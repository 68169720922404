import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {SupplierType} from '../types/supplierTypes';


function getAll(data: PaginationType) {
  return request({
    url: 'supplier/getAll',
    method: 'GET',
    params: data
  });
}
function create(data: SupplierType) {
  return request({
    url: 'supplier/create',
    method: 'POST',
    data: data
  });
}
function update(data: SupplierType) {
  const id = data.supplierUserId;
  data.supplierUserId = undefined;
  return request({
    url: `supplier/update/${id}`,
    method: 'PUT',
    data: data
  });
}

export default {
  getAll,
  create,
  update,
}