// Libraries
import React from 'react';
import {Row, Form, Modal, DatePicker, Popconfirm} from 'antd';
import moment, {Moment} from "moment";

// Store
import {useAppDispatch} from '../../store/store';
import {duplicateEvents} from "../../store/redux/actions/plannerActions";

// Types
import {DuplicateEventType} from "../../types/plannerTypes";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';

// Utils
import {DATE_FORMAT, END_DATE_FORMAT, START_DATE_FORMAT} from "../../utils/constants";


type PropsType = {
  selectedMonth: number;
  selectedYear: number;
  startDatetime: Moment;
  endDatetime: Moment;
  setDuplicateEvents: () => void;
  onToggleModal: (visible: boolean) => void;
};
const DuplicateFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const newStartDatetime = Form.useWatch('newStartDatetime', form);
  const { selectedMonth, selectedYear, startDatetime, endDatetime, setDuplicateEvents, onToggleModal } = props;
  const dispatch = useAppDispatch();
  const rangeText = `desde el ${moment(startDatetime).format(DATE_FORMAT)} hasta el ${moment(endDatetime).format(DATE_FORMAT)}`;

  const handleSubmit = (values: DuplicateEventType) => {
    const data: DuplicateEventType = {
      startDatetime: moment(startDatetime).format(START_DATE_FORMAT),
      endDatetime: moment(endDatetime).format(END_DATE_FORMAT),
      newStartDatetime: moment(values.newStartDatetime).format(START_DATE_FORMAT),
    }
    dispatch(duplicateEvents(data, selectedMonth + 1, selectedYear));
    setDuplicateEvents();
    onToggleModal(false);
  }

  return (
    <Modal title={'Duplicar eventos'}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <Popconfirm key={'submit'}
                         title={`Se duplicarán todos los eventos ${rangeText}, 
                          para el día ${moment(newStartDatetime).format(DATE_FORMAT)}`
                         }
                         okText={'Confirmar'}
                         onConfirm={() => form.submit()}>
               <ButtonComponent text={`Duplicar`}
                                className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                  'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} />
             </Popconfirm>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-1'}>
          <span className={'mb-5'}>
            Va a duplicar eventos {rangeText}
          </span>

          <FormItemComponent name={'newStartDatetime'}
                             label={'Fecha inicial donde quiere duplicar'}
                             className={'col-span-2'}
                             required={true}
                             child={<DatePicker className={'w-full'}
                                                format={DATE_FORMAT} />}
          />
        </Row>
      </Form>
    </Modal>
  );
}

export default DuplicateFormModal;
