import React from 'react';
import {Col} from "antd";

// Constants and Functions
import FormItemComponent from "../../subComponents/FormItemComponent";
import InputComponent from "../../subComponents/InputComponent";
import Account from "../Account";

// Store
import {useAppDispatch} from "../../../store/store";
import {register} from "../../../store/redux/actions/authActions";

// Types
import {SessionType} from "../../../types/sessionTypes";

// Utils
import {routes} from "../../../utils/routes";
import {getWindowInformation} from "../../../utils/functions";


const Login = () => {
  const dispatch = useAppDispatch();
  const { pathname } = getWindowInformation();
  const isSupplierUser = pathname === routes.supplierAccount.register;

  const handleSubmit = (values: SessionType) => {
    const registerValues: SessionType = {
      email: values.email,
      password: values.password,
      isSupplierUser: isSupplierUser,
    };

    dispatch(register(registerValues));
  }

  return (
    <Account title={'Registro'}
             buttonText={'Registrarme'}
             handleSubmit={handleSubmit}
             formContent={
               <>
                 <FormItemComponent name={'email'}
                                    label={'Correo'}
                                    required={true}
                                    child={<InputComponent className={'focus:outline-none focus:ring-grey-dkt-300 ' +
                                      'focus:border-grey-dkt-300 focus:border-0'} />}
                 />

                 <FormItemComponent name={'password'}
                                    label={'Contraseña'}
                                    required={true}
                                    child={<InputComponent type={'password'} />}
                 />

                 <FormItemComponent name={'confirmPassword'}
                                    label={'Confirmación de contraseña'}
                                    required={true}
                                    dependencies={['password']}
                                    newRules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Las contraseñas no coinciden'));
                                        },
                                      })
                                    ]}
                                    child={<InputComponent type={'password'} />}
                 />

                 <Col className={'text-right'}>
                   ¿Ya tienes una cuenta? <a href={isSupplierUser ? routes.supplierAccount.login : routes.carrierAccount.login}
                                             className={'text-blue-dkt-500 hover:underline font-semibold'}>Inicia sesión</a>
                 </Col>
               </>
             } />
  );
}

export default Login;