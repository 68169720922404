import React, {useEffect, useState} from 'react';
import {Popconfirm, Space} from 'antd';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../store/store';
import * as packageSizeSelectors from '../../store/redux/selectors/packageSizeSelectors';
import {getAll, remove} from '../../store/redux/actions/packageSizeActions';

// Types
import {PackageSizeType} from '../../types/packageSizeTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';

// Functions and Constants
import {getTableColumns} from '../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, isEditing?: boolean, packageSizeToEdit?: PackageSizeType) => void;
}
const PackageSizesTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [packageSizeList, setPackageSizeList] = useState<PackageSizeType[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10,
  });
  const { rows, count, size, page } = useSelector(packageSizeSelectors.allPackageSizesSelector);

  useEffect(() => {
    dispatch(getAll(paginationData));
  }, [paginationData]);

  useEffect(() => {
    const data: any[] = [];

    rows.map(packageSize => {
      data.push({
        packageSizeId: packageSize.packageSizeId,
        name: packageSize.name,
        height: packageSize.height,
        width: packageSize.width,
        depth: packageSize.depth,
        weight: packageSize.weight
      });
    });

    setPackageSizeList(data);
  }, [rows]);

  const handleFormModal = (visible: boolean, packageSizeId?: string) => {
    const packageSizeToEdit = rows.find(packageSize => packageSize.packageSizeId === packageSizeId);

    onToggleModal(visible, true, packageSizeToEdit);
  }

  const actionButtons = (packageSizeId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, packageSizeId)} />
        <Popconfirm title={'¿Deseas borrar esta dimensión?'}
                    okText={'Confirmar'}
                    cancelText={'Cancelar'}
                    onConfirm={() => dispatch(remove(packageSizeId))}>
          <DeleteOutlined className={'cursor-pointer hover:text-red-dkt-400'} />
        </Popconfirm>
      </Space>
    )
  };

  return (
    <TableComponent pageSize={size}
                    currentPage={page}
                    total={count}
                    dataSource={packageSizeList}
                    scrollX={280}
                    rowKey={'packageSizeId'}
                    columns={getTableColumns('packageSizes', actionButtons)}
                    onChangePage={setPaginationData} />
  );
}

export default PackageSizesTable;