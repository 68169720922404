// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import carrierServices from '../../../api/carrierServices';

// Types
import {carrierTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {CarrierType} from '../../../types/carrierTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getAll = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  carrierServices.getAll(data)
    .then((response) => {
      dispatch({
        type: carrierTypes.GET_ALL_CARRIERS,
        carriersResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (carrier: CarrierType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  carrierServices.create(carrier)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (carrier: CarrierType, carrierId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  carrierServices.update(carrier, carrierId)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
