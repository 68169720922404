// Dependencies
import { combineReducers } from 'redux';

// Reducers
import generalReducer from './generalReducer';
import sessionReducer from './sessionReducer';
import remissionReducer from './remissionReducer';
import ctnReducer from './ctnReducer';
import tariffReducer from './tariffReducer';
import thresholdReducer from './thresholdReducer';
import inboundReducer from './inboundReducer';
import userReducer from './userReducer';
import allianceReducer from './allianceReducer';
import storeReducer from "./storeReducer";
import packageSizeReducer from "./packageSizeReducer";
import orderReducer from "./orderReducer";
import locationReducer from "./locationReducer";
import plannerReducer from "./plannerReducer";
import supplierReducer from "./supplierReducer";
import carrierReducer from "./carrierReducer";
import carrierUserReducer from "./carrierUserReducer";
import storeMapReducer from "./storeMapReducer";
import userStoreMapReducer from "./userStoreMapReducer";
import transportReducer from "./transportReducer";

// Reducers
export default combineReducers({
  generalReducer: generalReducer,
  sessionReducer: sessionReducer,
  remissionReducer: remissionReducer,
  ctnReducer: ctnReducer,
  tariffReducer: tariffReducer,
  thresholdReducer: thresholdReducer,
  inboundReducer: inboundReducer,
  userReducer: userReducer,
  allianceReducer: allianceReducer,
  storeReducer: storeReducer,
  packageSizeReducer: packageSizeReducer,
  orderReducer: orderReducer,
  locationReducer: locationReducer,
  plannerReducer: plannerReducer,
  supplierReducer:supplierReducer,
  carrierReducer: carrierReducer,
  carrierUserReducer: carrierUserReducer,
  storeMapReducer: storeMapReducer,
  userStoreMapReducer: userStoreMapReducer,
  transportReducer: transportReducer,
});