// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import carrierUserServices from '../../../api/carrierUserServices';

// Types
import {carrierUserTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {CarrierUserType} from '../../../types/carrierUserTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getParams = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  carrierUserServices.getParams()
    .then((response) => {
      dispatch({
        type: carrierUserTypes.GET_CARRIER_USER_PARAMS,
        params: response.data
      });
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAll = (data: PaginationType) => async (dispatch: AppDispatch) => {
  carrierUserServices.getAll(data)
    .then((response) => {
      dispatch({
        type: carrierUserTypes.GET_ALL_CARRIER_USERS,
        carrierUsersResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (carrierUser: CarrierUserType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  carrierUserServices.create(carrierUser)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (carrierUser: CarrierUserType, carrierUserId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  carrierUserServices.update(carrierUser, carrierUserId)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
