import React, {useEffect, useState} from 'react';
import {Col, Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../store/store';
import * as locationSelectors from '../../store/redux/selectors/locationSelectors';
import {getAll} from '../../store/redux/actions/locationActions';

// Types
import {LocationType, LocationPaginationType} from '../../types/locationTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';
import FiltersComponent from "../subComponents/FiltersComponent";

// Functions and Constants
import {getFilterParameters, getTableColumns} from '../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, isEditing?: boolean, locationToEdit?: LocationType) => void;
}
const LocationsTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [locationList, setLocationList] = useState<LocationType[]>([]);
  const [paginationData, setPaginationData] = useState<LocationPaginationType>({
    paginationVm: { page: 1, size: 10 },
    filterLocationVm: { },
  });
  const { rows, count, size, page } = useSelector(locationSelectors.allLocationsSelector);
  const filterParameters = getFilterParameters('location');

  useEffect(() => {
    dispatch(getAll(paginationData));
  }, [paginationData]);

  useEffect(() => {
    const data: LocationType[] = [];

    rows.map(location => {
      data.push({
        ...location,
      });
    });

    setLocationList(data);
  }, [rows]);

  const handleFormModal = (visible: boolean, locationId?: string) => {
    const locationToEdit = rows.find(location => location.locationId === locationId);

    onToggleModal(visible, true, locationToEdit);
  }

  const actionButtons = (locationId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, locationId)} />
      </Space>
    )
  };

  return (
    <>
      <Col md={24} xs={24} className={'mt-5'}>
        <FiltersComponent filterParameters={filterParameters}
                          onChangeFilters={(values) => setPaginationData({
                            paginationVm: { page, size },
                            filterLocationVm: values
                          })} />
      </Col>

      <TableComponent pageSize={size}
                      currentPage={page}
                      total={count}
                      dataSource={locationList}
                      scrollX={700}
                      rowKey={'locationId'}
                      columns={getTableColumns('locations', actionButtons)}
                      onChangePage={(pagination) => setPaginationData({
                        paginationVm: pagination,
                        filterLocationVm: paginationData.filterLocationVm
                      })} />
    </>
  );
}

export default LocationsTable;