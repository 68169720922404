// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import supplierServices from '../../../api/supplierServices';

// Types
import {supplierTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {SupplierType} from '../../../types/supplierTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getAll = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  supplierServices.getAll(data)
    .then((response) => {
      dispatch({
        type: supplierTypes.GET_ALL_SUPPLIERS,
        suppliersResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (supplier: SupplierType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  supplierServices.create(supplier)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (supplier: SupplierType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  supplierServices.update(supplier)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
