// Libraries
import React, {useEffect, useState} from 'react';

// Utils
import {useSelector} from "react-redux";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";

// Utils
import {getTableColumns} from "../../../utils/componentsUtils";
import TableComponent from "../../subComponents/TableComponent";

// Types
import {ReturnDetailType} from "../../../types/transportTypes";


const ReturnInformation = () => {
  const [returnList, setReturnList] = useState<ReturnDetailType[]>([]);
  const { stores } = useSelector(transportSelectors.transportParamsSelector);
  const detail = useSelector(transportSelectors.transportDetailSelector);

  useEffect(() => {
    if(detail.returns) {
      const newReturnList: ReturnDetailType[] = [];
      detail.returns.map((item, index) => {
        newReturnList.push({
          ...item,
          returnId: index,
          storeName: stores.find((store) => store.id === item.storeId)?.value as string ?? '-'
        })
      });

      setReturnList(newReturnList);
    }
  }, [detail]);


  return (
    <TableComponent pageSize={returnList.length}
                    currentPage={1}
                    total={returnList.length}
                    dataSource={returnList}
                    scrollX={1000}
                    rowKey={'returnId'}
                    className={'[&>div>div>ul]:hidden'}
                    columns={getTableColumns('returns', () => null)}
                    onChangePage={() => null} />
  );
}

export default ReturnInformation;
