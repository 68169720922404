// Libraries
import React, {FunctionComponent} from 'react';
import {Col, Row, Form, Space, DatePicker} from 'antd';
import PropTypes from 'prop-types';
import {DeleteOutlined, SearchOutlined} from '@ant-design/icons';

// Types
import {FilterParametersType, FilterType} from '../../types/sharedTypes';

// Components and Subcomponents
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import SelectComponent from "./SelectComponent";

// Utils
import {DATE_FORMAT} from "../../utils/constants";
import {screenSizeValidator} from "../../utils/functions";

const { RangePicker } = DatePicker;


type FiltersComponentType = {
  selectList?: any[];
  filterParameters: FilterParametersType[];
  onChangeFilters: (filters: FilterType) => void;
}
const FiltersComponent: FunctionComponent<FiltersComponentType> = ({
    selectList,
    filterParameters,
    onChangeFilters
  }) => {
  const [form] = Form.useForm();
  const smallWindow = screenSizeValidator('(max-width: 767px)');

  const onSubmit = (values: FilterType, resetForm = false) => {
    if(resetForm){
      form.resetFields();
    }
    onChangeFilters(values);
  };

  return (
    <Row gutter={[8, 8]} className={'mb-4'}>
      <Col lg={24} md={24} xs={24}>
        <Form form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onSubmit}
              className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-2'}>
          {filterParameters.map(filter =>
            <Col key={filter.fieldName} className={'w-full inline-block'}>
              <FormItemComponent name={filter.fieldName}
                                 label={''}
                                 required={false}
                                 className={'mb-0'}
                                 child={
                                   filter.type === 'rangeDate'
                                     ? <RangePicker placeholder={filter.placeHolder as [string, string]}
                                                    className={'w-full h-9 rounded-md border border-grey-dkt-300'}
                                                    format={DATE_FORMAT}
                                                    allowClear={false} />
                                     : filter.type === 'select'
                                       ? <SelectComponent className={'w-full h-9'}
                                                          list={selectList ?? []}
                                                          placeHolder={filter.placeHolder as string} />
                                       : <InputComponent className={'w-full h-9 top-[2px]'}
                                                         placeHolder={filter.placeHolder as string} />
                                 } />
            </Col>
          )}
          <Col lg={3} md={3} xs={24} className={'w-full inline-grid'}>
            <Space>
              <ButtonComponent text={smallWindow ? 'Buscar' : ''}
                               icon={<SearchOutlined />}
                               htmlType={'submit'} />

              <ButtonComponent text={smallWindow ? 'Borrar' : ''}
                               icon={<DeleteOutlined />}
                               onClick={() => onSubmit({}, true)} />
            </Space>
          </Col>
        </Form>
      </Col>
    </Row>
  );
}

FiltersComponent.propTypes = {
  filterParameters: PropTypes.any,
  onChangeFilters: PropTypes.func.isRequired,
};
FiltersComponent.defaultProps = {
  filterParameters: [],
  onChangeFilters: () => {
    return;
  }
};

export default FiltersComponent;