// Libraries
import React, {useEffect, useState} from 'react';
import {Row, Form, Modal, DatePicker, Switch, Col, Popover, Tooltip, Button, Popconfirm, Collapse, Divider} from 'antd';
import ColorPicker from "react-pick-color";
import moment from "moment";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";

// Store
import {useAppDispatch} from '../../store/store';
import { create, update, remove, getAllByMonth } from '../../store/redux/actions/plannerActions';
import { create as createService } from '../../store/redux/actions/transportActions';
import * as plannerSelectors from "../../store/redux/selectors/plannerSelectors";

// Types
import {PlannerType} from "../../types/plannerTypes";
import {TransportType} from '../../types/transportTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import SelectComponent from "../subComponents/SelectComponent";
import InputComponent from '../subComponents/InputComponent';

// Utils
import {DATETIME_FORMAT, DEFAULT_DATETIME_FORMAT, FULL_DATETIME_FORMAT} from "../../utils/constants";


type PropsType = {
  isEditing?: boolean;
  plannerToEdit?: PlannerType;
  onToggleModal: (visible: boolean) => void;
};
const PlanningFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const { isEditing, plannerToEdit, onToggleModal } = props;
  const dispatch = useAppDispatch();

  const [pickColorOpen, setPickColorOpen] = useState<boolean>(false);
  const [isAddingService, setIsAddingService] = useState<boolean>(false);
  const [preselectedColor, setPreselectedColor] = useState<string>('#000000');
  const [activePanel, setActivePanel] = useState<string | string[]>('downloads');
  const [selectedColor, setSelectedColor] = useState<string>(isEditing ? plannerToEdit!.hexColor : '#E6EAFC');
  const [defaultBackgroundColors, setDefaultBackgroundColors] = useState<string[]>(
    ['#E6EAFC', '#FFF3DC', '#FFD5D9', '#DCFFE5', '#FFE0C3', '#DBCCEF', '#FCFFB1', '#E7E7E7']
  );

  const { stores, carriers } = useSelector(plannerSelectors.plannerParamsSelector);

  const colorPickerContent = (
    <Col className={'text-right'}>
      <ColorPicker color={selectedColor}
                   hideAlpha={true}
                   onChange={(color) => setPreselectedColor(color.hex)} />

      <ButtonComponent text={'Aceptar'}
                       className={'ant-btn-primary mt-2'}
                       onClick={() => {
                         setDefaultBackgroundColors([...defaultBackgroundColors, preselectedColor]);
                         setPickColorOpen(false);
                       }} />
    </Col>
  );

  useEffect(() => {
    if(isEditing && plannerToEdit) {
      form.setFieldsValue({
        withReturn: plannerToEdit.withReturn,
        dateTime: moment(plannerToEdit.startDatetime),
        unloadsInfo: plannerToEdit.unloadsInfo.map((unload) => ({
          storeId: unload.storeId,
          dateTime: moment(unload.endDatetime),
        })),
        carrierId: null,
        restrictions: '',
      });
    }
  }, [isEditing, plannerToEdit]);

  const handleEditOrCreate = (data: PlannerType) => {
    const planner: PlannerType = {
      withReturn: data.withReturn ?? false,
      startDatetime: moment(data.dateTime).format(DEFAULT_DATETIME_FORMAT),
      hexColor: selectedColor,
      unloadsInfo: data.unloadsInfo.map((unload) => ({
        storeId: unload.storeId,
        endDatetime: moment(unload.dateTime).format(DEFAULT_DATETIME_FORMAT),
      })),
    };
    const [month, year] = [moment(planner.startDatetime).month() + 1, moment(planner.startDatetime).year()];
    data.dateTime = undefined;

    if(isEditing){
      planner.plannerId = plannerToEdit?.plannerId;
      dispatch(update(planner, month, year));
    } else {
      dispatch(create(planner, month, year));
    }
  }

  const handleCreateService = (data: PlannerType) => {
    const [month, year] = [moment(plannerToEdit!.startDatetime).month() + 1, moment(plannerToEdit!.startDatetime).year()];
    const service: TransportType = {
      carrierId: data.carrierId,
      plannerId: plannerToEdit!.plannerId,
      withReturn: plannerToEdit!.withReturn,
      restrictions: data.restrictions,
      startDatetime: plannerToEdit!.startDatetime,
      hexColor: plannerToEdit!.hexColor,
      destinations: plannerToEdit!.unloadsInfo.map((unload) => ({
        destinationStoreId: unload.storeId,
        restrictions: data.unloadsInfo.find(({ storeId }) => storeId == unload.storeId)?.restrictions ?? '',
        unloadDateTime: unload.endDatetime,
      }))
    };

    dispatch(createService(service, getAllByMonth(month, year)));
    handleShowServicePanel(true);
    form.resetFields(["carrierId", "restrictions"]);
  }

  const handleSubmit = (data: PlannerType) => {
    if(isAddingService){
      handleCreateService(data);
    } else {
      handleEditOrCreate(data);
      onToggleModal(false);
    }
  }

  const handleShowServicePanel = (isEditingEvent: boolean) => {
    setActivePanel(isEditingEvent ? 'downloads' : ['downloads', 'service']);
    setIsAddingService(!isEditingEvent);
  }

  const handleRemove = () => {
    const plannerId = plannerToEdit!.plannerId!;
    const month = moment(plannerToEdit!.startDatetime).month() + 1;
    const year = moment(plannerToEdit!.startDatetime).year();

    dispatch(remove(plannerId, month, year));
    onToggleModal(false);
  }

  const handleSubmitConfirmation = () => {
    const isSubmitRequired = isEditing && plannerToEdit?.transportEngineId
    if(!isSubmitRequired){
      form.submit()
    }
  }

  return (
    <Modal open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           title={
             <Col className={'grid grid-cols-2'}>
               <span>{isEditing ? 'Editar' : 'Crear'} planeación</span>
               {isEditing &&
                  <span className={'text-right'}>
                    <Popconfirm key={'confirm'}
                                okText={'Confirmar'}
                                title={'¿Está seguro?, los datos modificados se perderán.'}
                                onConfirm={() => handleShowServicePanel(isAddingService)}>
                      <Switch className={'same-style'}
                              checked={!isAddingService}
                              checkedChildren={"Editar planeación"}
                              unCheckedChildren={"Agregar servicio"}
                              defaultChecked={true}
                      />
                    </Popconfirm>
                  </span>
               }
             </Col>
           }
           footer={[
             isEditing && plannerToEdit && !isAddingService && (
               <Popconfirm key={'delete'}
                           okText={'Confirmar'}
                           title={plannerToEdit.transportEngineId 
                                  ? 'Esta planeación ya tiene un servicio asociado, el cual no se eliminará. ¿Desea eliminar la planeación?' 
                                  : '¿Confirma que desea eliminar este evento?'}
                           onConfirm={handleRemove}>
                 <ButtonComponent text={'Eliminar'}
                                  className={'text-white bg-red-dkt-400 hover:bg-red-dkt-500 ' +
                                    'focus:ring-red-dkt-300 float-left'}/>
               </Popconfirm>
             ),
             <ButtonComponent key={'cancel'}
                              text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <Popconfirm key={'submit'}
                        okText={'Confirmar'}
                        disabled={!(isEditing && plannerToEdit?.transportEngineId)}
                        title={!isAddingService 
                               ? 'Esta planeación ya tiene un servicio asociado, el cual no será modificado. ¿Desea editar la planeación?'
                               : 'Esta planeación ya tiene un servicio asociado ¿Desea crear otro servicio?'}
                        onConfirm = {() => form.submit()}>
               <ButtonComponent text={`${isEditing ? !isAddingService ? 'Editar' : 'Solicitar' : 'Crear'}`}
                                className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                  'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                                onClick = {() => handleSubmitConfirmation()}/>
             </Popconfirm>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          <FormItemComponent name={'withReturn'}
                             className={'col-span-2'}
                             label={''}
                             required={false}
                             valuePropName={'checked'}
                             child={<Switch checkedChildren={"Con retorno"}
                                            unCheckedChildren={"Sin retorno"} 
                                            disabled={isAddingService}/>}
          />
          <FormItemComponent name={'dateTime'}
                             label={'Fecha y hora'}
                             className={'col-span-2'}
                             required={true}
                             child={<DatePicker showTime={{ format: 'HH:mm' }}
                                                className={'w-full'}
                                                format={FULL_DATETIME_FORMAT} 
                                                disabled={isAddingService}/>}
          />
          <FormItemComponent name={'hexColor'}
                             label={'Color'}
                             required={false}
                             className={'col-span-2'}
                             child={
                               <Col className={'grid grid-cols-8 w-full gap-y-2'}>
                                 {defaultBackgroundColors.map((color) =>
                                   <Button key={color}
                                           style={{ backgroundColor: color }}
                                           disabled={isAddingService}
                                           onClick={() => setSelectedColor(color)}
                                           className={`visible rounded-[50%] h-[35px] w-[35px] 
                                             ${color === selectedColor && 'border-black'}`
                                           } />
                                 )}

                                 {isEditing && !defaultBackgroundColors.includes(plannerToEdit!.hexColor) &&
                                   <Button style={{ backgroundColor: plannerToEdit!.hexColor }}
                                           disabled={isAddingService}
                                           onClick={() => setSelectedColor(plannerToEdit!.hexColor)}
                                           className={`visible rounded-[50%] h-[35px] w-[35px] 
                                             ${plannerToEdit?.hexColor.includes(selectedColor) && 'border-grey-dkt-600'}`
                                           } />
                                 }

                                 {!isAddingService &&
                                   <Popover content={colorPickerContent}
                                            open={pickColorOpen}
                                            trigger={"click"}
                                            placement={'right'}>
                                   <ButtonComponent text={''}
                                                    icon={<PlusOutlined />}
                                                    onClick={() => setPickColorOpen(true)}
                                                    className={'text-grey-dkt-400 rounded-[50%] h-[35px] w-[35px]'} />
                                 </Popover>}
                               </Col>
                             }
          />
          <Col className={'col-span-2'}>
            <Collapse ghost={true}
                      defaultActiveKey={activePanel}
                      activeKey={activePanel}
                      onChange={setActivePanel}>
              <Collapse.Panel key={'downloads'}
                              showArrow={true}
                              className={'[&>div]:px-2 [&>div]:py-2 [&>div.ant-collapse-header]:shadow-md ' +
                                '[&>div.ant-collapse-header]:border [&>div.ant-collapse-header]:border-grey-dkt-300 ' +
                                '[&>div.ant-collapse-header]:border-opacity-30 ' +
                                '[&>div>div>span]:inline-grid [&>div:last-child>div]:px-0 [&>div:first-child]:bg-grey-dkt-200 mb-1'
                              }
                              header={'Descargas'}>
                <Form.List name={"unloadsInfo"} initialValue={[{ storeId: '', endDatetime: '' }]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }) => (
                        <Col key={key}>
                          <Col className={`${isAddingService ? 'grid grid-cols-10' : ' grid grid-cols-11'}` + ' gap-x-2'}>
                            <FormItemComponent name={[name, 'storeId']}
                                              label={'Tienda'}
                                              required={true}
                                              className={'col-span-5'}
                                              child={
                                                <SelectComponent list={stores}
                                                                 disabled={isAddingService}/>
                                              }
                            />
                            <FormItemComponent name={[name, 'dateTime']}
                                              label={'Fecha y hora'}
                                              required={true}
                                              className={'col-span-5'}
                                              child={
                                                <DatePicker showTime
                                                            className={'w-full h-9 rounded-md border border-grey-dkt-300'}
                                                            format={DATETIME_FORMAT}
                                                            disabled={isAddingService}/>
                                                }
                            />
                            {isAddingService &&
                              <FormItemComponent name={[name, 'restrictions']}
                                                 label={'Restricciones'}
                                                 required={true}
                                                 className={'col-span-10'}
                                                 child={<InputComponent />}
                              />
                            }
                            {fields.length > 1 && !isAddingService &&
                              <Col className={'col-span-1 grid'}>
                                <Tooltip title={'Quitar descarga'} >
                                  <DeleteOutlined className={'text-lg m-auto cursor-pointer hover:text-red-dkt-400'}
                                                  onClick={() => remove(name)} disabled={isAddingService}/>
                                </Tooltip>
                              </Col>
                            }
                          </Col>
                          {key < fields.length - 1 && <Divider className={' mt-0 mb-1'} />}
                        </Col>
                      ))}
                      {!isAddingService && <Form.Item>
                        <ButtonComponent icon={<PlusOutlined />}
                                         text={'Agregar descarga'}
                                         className={'ant-btn-primary'}
                                         disabled={isAddingService}
                                         onClick={add} />
                      </Form.Item>}
                    </>
                  )}
                </Form.List>
              </Collapse.Panel>
              {isAddingService &&
                <Collapse.Panel key={'service'}
                                showArrow={true}
                                className={'[&>div]:px-2 [&>div]:py-2 [&>div.ant-collapse-header]:shadow-md ' +
                                  '[&>div.ant-collapse-header]:border [&>div.ant-collapse-header]:border-grey-dkt-300 ' +
                                  '[&>div.ant-collapse-header]:border-opacity-30 ' +
                                  '[&>div>div>span]:inline-grid [&>div:last-child>div]:px-0 [&>div:first-child]:bg-grey-dkt-200 mb-1'
                                }
                                header={'Solicitud de servicio'}>
                  <Col className={'grid grid-cols-10 gap-x-2'}>
                    <FormItemComponent name={'carrierId'}
                                       label={'Transportista'}
                                       required={true}
                                       className={'col-span-10'}
                                       child={<SelectComponent list={carriers} />}
                    />
                    <FormItemComponent name={'restrictions'}
                                       label={'Restricciones'}
                                       required={true}
                                       className={'col-span-10'}
                                       child={<InputComponent />}
                    />
                  </Col>
                </Collapse.Panel>
              }
            </Collapse>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default PlanningFormModal;
