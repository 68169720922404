// Constants
import {AllianceActionType} from '../../../types/allianceTypes';
import {allianceTypes} from '../types';

const initialState = {
  alliancesResult: { count: 0, page: 1, size: 10, rows: [] },
  params: [],
}

const allianceReducer = (state = initialState, action: AllianceActionType) => {
  switch (action.type) {
    case allianceTypes.GET_ALLIANCE_PARAMS:
      return {
        ...state,
        params: action.params
      };
    case allianceTypes.GET_ALL_ALLIANCES:
      return {
        ...state,
        alliancesResult: action.alliancesResult
      };
    default:
      return state;
  }
};

export default allianceReducer;
