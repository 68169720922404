import React from 'react';
import {Drawer, List} from 'antd';

// Types
import {PackageItemType} from "../../types/shipmentTypes";


type PropsType = {
  packageItems: PackageItemType[];
  onToggleDrawer: (visible: boolean, packageItems: PackageItemType[]) => void;
};
const ItemsDrawer = (props: PropsType) => {
  const { packageItems, onToggleDrawer } = props;

  return (
    <Drawer title={"Artículos del paquete"}
            placement={"right"}
            onClose={() => onToggleDrawer(false, [])}
            open={true}>
      <List dataSource={packageItems}
            size={'small'}
            bordered={false}
            renderItem={item => (
              <List.Item key={item.packageItemId}>
                <List.Item.Meta title={<span>{item.name}</span>}
                                description={`Referencia: ${item.reference}`}
                />
              </List.Item>
            )}
      />
    </Drawer>
  );
};

export default ItemsDrawer;