export const formFields = [
  { name: "vehicleAnnouncementDate", type: "date", label: "Hora anuncio de cargue" },
  { name: "loadingStartDate", type: "date", label: "Hora inicio de cargue" },
  { name: "loadingEndDate", type: "date", label: "Hora fin de cargue" },
  { name: "dispatchNumber", type: "string", label: "Número de expedición" },
  { name: "uatNumber", type: "string", label: "Número de UAT" },
  { name: "numberOfBoxes", type: "number", label: "Número de cajas" },
  { name: "numberOfPallets", type: "number", label: "Número de pallets" },
  { name: "bulkBoxes", type: "number", label: "Cajas a granel" },
  { name: "sentQuantities", type: "number", label: "Cantidades enviadas" },
  { name: "interstoreShipments", type: "number", label: "Intertiendas" },
  { name: "dekaproShipments", type: "number", label: "Dekapro" },
  { name: "ccShipments", type: "number", label: "C&C" },
  { name: "shipmentsWithoutFlows", type: "number", label: "Envíos sin flujos" },
  { name: "claims", type: "number", label: "Reclamaciones" },
  { name: "advertising", type: "number", label: "Publicidad" },
  { name: "agencyFees", type: "number", label: "Agenciamiento" },
  { name: "baskets", type: "number", label: "Canastas" },
  { name: "dollies", type: "number", label: "Dollies" },
  { name: "lids", type: "number", label: "Tapas" },
  { name: "magnusShipments", type: "number", label: "Magnus" },
];