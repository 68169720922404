// Constants
import {UserStoreMapActionType} from '../../../types/userStoreMapTypes';
import {userStoreMapTypes} from '../types';

const initialState = {
  userStoreMapsResult: { count: 0, page: 1, size: 10, rows: [] },
  params: [],
}

const userStoreMapReducer = (state = initialState, action: UserStoreMapActionType) => {
  switch (action.type) {
    case userStoreMapTypes.GET_USER_STORE_MAPS_PARAMS:
      return {
        ...state,
        params: action.params
      };
    case userStoreMapTypes.GET_ALL_USER_STORE_MAPS:
      return {
        ...state,
        userStoreMapsResult: action.userStoreMapsResult
      };
    default:
      return state;
  }
};

export default userStoreMapReducer;
