import React, { useState } from 'react';
import {Row, Col} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

// Types
import {StoreType} from "../../types/storeTypes";

// Components and Functions
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import StoresTable from './StoresTable';
import StoresFormModal from './StoresFormModal';


const Stores = () => {
  const [storeToEdit, setStoreToEdit] = useState<StoreType | undefined>(undefined)
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleModalVisible = (visible: boolean, isEditing?: boolean, storeToEdit?: StoreType) => {
    setFormModalVisible(visible);
    setIsEditing(isEditing ?? false);
    setStoreToEdit(storeToEdit ?? undefined);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3}
                        text={'Centros'}
                        className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={10} sm={10} xs={24} className={'text-right'}>
        <ButtonComponent text={'Crear centro'}
                         icon={<PlusOutlined />}
                         className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                         onClick={() => handleModalVisible(true)}/>
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <StoresTable onToggleModal={handleModalVisible} />
      </Col>

      {formModalVisible &&
        <StoresFormModal isEditing={isEditing}
                         storeToEdit={storeToEdit}
                         onToggleModal={handleModalVisible} />
      }
    </Row>
  );
}

export default Stores;