import {plannerTypes} from '../types';

// Types
import {PlannerActionType} from "../../../types/plannerTypes";

const initialState = {
  params: {
    stores: [],
    carriers: [],
  },
  planner: [],
}

const plannerReducer = (state = initialState, action: PlannerActionType) => {
  switch (action.type) {
    case plannerTypes.GET_PLANNER_PARAMS:
      return {
        ...state,
        params: action.params
      };
    case plannerTypes.GET_ALL_PLANNER:
      return {
        ...state,
        planner: action.planner
      };
    default:
      return state;
  }
};

export default plannerReducer;
