// Constants
import {OrderActionType} from '../../../types/orderTypes';
import {orderTypes} from '../types';

const initialState = {
  ordersResult: { count: 0, page: 1, size: 10, rows: [] },
  orderDetail: {
    shipments: []
  }
}

const orderReducer = (state = initialState, action: OrderActionType) => {
  switch (action.type) {
    case orderTypes.GET_ALL_ORDERS:
      return {
        ...state,
        ordersResult: action.ordersResult,
      };
    case orderTypes.GET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.orderDetail,
      };
    default:
      return state;
  }
};

export default orderReducer;
