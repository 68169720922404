import React from 'react';
import {Col} from "antd";

// Components
import FormItemComponent from "../../subComponents/FormItemComponent";
import InputComponent from "../../subComponents/InputComponent";
import Account from "../Account";

// Types
import {SessionType} from "../../../types/sessionTypes";

// Utils
import {routes} from "../../../utils/routes";
import {getWindowInformation} from "../../../utils/functions";

// Store
import {useAppDispatch} from "../../../store/store";
import {forgotPassword} from "../../../store/redux/actions/authActions";


const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const { pathname } = getWindowInformation();
  const isSupplierUser = pathname === routes.supplierAccount.forgotPassword;

  const handleSubmit = (values: SessionType) => {
    values.isSupplierUser = isSupplierUser;
    dispatch(forgotPassword(values));
  }

  return (
    <Account title={'Recuperar contraseña'}
             buttonText={'Recuperar'}
             handleSubmit={handleSubmit}
             formContent={
               <>
                 <FormItemComponent name={'email'}
                                    label={'Correo'}
                                    required={true}
                                    child={<InputComponent className={'focus:outline-none focus:ring-grey-dkt-300 ' +
                                      'focus:border-grey-dkt-300 focus:border-0'} />}
                 />

                 <Col className={'text-right'}>
                   ¿Ya la recuerdas? <a href={isSupplierUser ? routes.supplierAccount.login : routes.carrierAccount.login}
                                             className={'text-blue-dkt-500 hover:underline font-semibold'}>Inicia sesión</a>
                 </Col>
               </>
             } />
  );
}

export default ForgotPassword;