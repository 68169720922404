import React, {useEffect, useState} from 'react';
import {Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../store/store';
import * as supplierSelectors from '../../store/redux/selectors/supplierSelectors';
import { getAll } from '../../store/redux/actions/supplierActions';

// Types
import {SupplierType} from '../../types/supplierTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';

// Functions and Constants
import {getTableColumns} from '../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, isEditing?: boolean, supplierToEdit?: SupplierType) => void;
}
const SuppliersTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [supplierList, setSupplierList] = useState<SupplierType[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10
  });
  const { rows, count, size, page } = useSelector(supplierSelectors.allSuppliersSelector);

  useEffect(() => {  
    dispatch(getAll(paginationData));
  }, [paginationData]);

  useEffect(() => {
    const data: SupplierType[] = [];

    rows.map(supplier => {
      data.push({
        ...supplier,
        confirmed: supplier.confirmedEmail ? 'SI' : 'NO',
        isActive: supplier.isActive ? 'SI' : 'NO',
      });
    });

    setSupplierList(data);
  }, [rows]);

  const handleFormModal = (visible: boolean, supplierUserId?: string) => {
    const supplierToEdit = rows.find(supplier => supplier.supplierUserId === supplierUserId);

    onToggleModal(visible, true, supplierToEdit);
  }

  const actionButtons = (supplierUserId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, supplierUserId)} />
      </Space>
    )
  };

  return (
    <TableComponent pageSize={size}
                    currentPage={page}
                    total={count}
                    dataSource={supplierList}
                    scrollX={900}
                    rowKey={'supplierUserId'}
                    columns={getTableColumns('suppliers', actionButtons)}
                    onChangePage={setPaginationData} />
  );
}

export default SuppliersTable;
