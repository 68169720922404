// Constants
import {StoreMapActionType} from '../../../types/storeMapTypes';
import {storeMapTypes} from '../types';

const initialState = {
  storeMapsResult: { count: 0, page: 1, size: 10, rows: [] },
}

const storeMapReducer = (state = initialState, action: StoreMapActionType) => {
  switch (action.type) {
    case storeMapTypes.GET_ALL_STORE_MAPS:
      return {
        ...state,
        storeMapsResult: action.storeMapsResult
      };
    default:
      return state;
  }
};

export default storeMapReducer;
