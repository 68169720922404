// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import userStoreMapServices from '../../../api/userStoreMapServices';

// Types
import {userStoreMapTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {UserStoreMapType} from "../../../types/userStoreMapTypes";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getParams = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userStoreMapServices.getParams()
    .then((response) => {
      dispatch({
        type: userStoreMapTypes.GET_USER_STORE_MAPS_PARAMS,
        params: response.data
      });
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAll = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userStoreMapServices.getAll(data)
    .then((response) => {
      dispatch({
        type: userStoreMapTypes.GET_ALL_USER_STORE_MAPS,
        userStoreMapsResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (id: string, userStoreMap: UserStoreMapType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userStoreMapServices.update(id, userStoreMap)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const sync = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userStoreMapServices.sync()
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
