// Types
import {carrierTypes} from '../types';
import {CarriersActionType} from '../../../types/carrierTypes';

const initialState = {
  carriersResult: {
    count: 0,
    page: 1,
    size: 10,
    rows: []
  },
}

const carrierReducer = (state = initialState, action: CarriersActionType) => {
  switch (action.type) {
    case carrierTypes.GET_ALL_CARRIERS:
      return {
        ...state,
        carriersResult: action.carriersResult
      };
    default:
      return state;
  }
};

export default carrierReducer;
