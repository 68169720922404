import {AxiosRequestConfig} from "axios";

const urlByController = {
  'alliance': process.env.REACT_APP_API_URL_2,
  'ctn': process.env.REACT_APP_API_URL,
  'inbound': process.env.REACT_APP_API_URL,
  'remission': process.env.REACT_APP_API_URL,
  'store':  process.env.REACT_APP_API_URL_2,
  'tariff':  process.env.REACT_APP_API_URL,
  'threshold':  process.env.REACT_APP_API_URL,
  'user':  process.env.REACT_APP_API_URL,
  'packageSize':  process.env.REACT_APP_API_URL_2,
  'order':  process.env.REACT_APP_API_URL_2,
  'location':  process.env.REACT_APP_API_URL_2,
  'planner':  process.env.REACT_APP_API_URL_3,
  'supplier':  process.env.REACT_APP_API_URL_3,
  'auth':  process.env.REACT_APP_API_URL_3,
  'carrier':  process.env.REACT_APP_API_URL_3,
  'carrierUser':  process.env.REACT_APP_API_URL_3,
  'storeMap':  process.env.REACT_APP_API_URL_3,
  'userStoreMap':  process.env.REACT_APP_API_URL_3,
  'transport':  process.env.REACT_APP_API_URL_3,
};

export const getBaseUrl = (options?: AxiosRequestConfig<any>) => {
  let baseURL = process.env.REACT_APP_API_URL;

  if (options) {
    const controller = options.url!.split('/')[0];
    baseURL = urlByController[controller as keyof typeof urlByController];
  }

  return baseURL;
}