// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import orderServices from '../../../api/orderServices';

// Types
import {orderTypes} from '../types';
import {OrderPaginationType} from "../../../types/orderTypes";

// Components and Subcomponents
import {ERROR_MODAL} from '../../../components/subComponents/responseModals';


export const getDetailByOrderNumber = (orderNumber: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch({
    type: orderTypes.GET_ORDER_DETAIL,
    orderDetail: { shipments: [] }
  });
  orderServices.getDetailByOrderNumber(orderNumber)
    .then((response) => {
      dispatch({
        type: orderTypes.GET_ORDER_DETAIL,
        orderDetail: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAll = (data: OrderPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  orderServices.getAll(data)
    .then((response) => {
      dispatch({
        type: orderTypes.GET_ALL_ORDERS,
        ordersResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}