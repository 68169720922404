// Constants
import {LocationActionType} from '../../../types/locationTypes';
import {locationTypes} from '../types';

const initialState = {
  locationsResult: { count: 0, page: 1, size: 10, rows: [] },
}

const locationReducer = (state = initialState, action: LocationActionType) => {
  switch (action.type) {
    case locationTypes.GET_ALL_LOCATIONS:
      return {
        ...state,
        locationsResult: action.locationsResult
      };
    default:
      return state;
  }
};

export default locationReducer;
