import React, {useState} from 'react';
import {Row, Col} from 'antd';

// Components and Functions
import TitleComponent from '../subComponents/TitleComponent';
import CalendarComponent from "./Calendar";
import PlanningFormModal from "./PlanningFormModal";

// Types
import {PlannerType} from "../../types/plannerTypes";


const Planning = () => {
  const [plannerToEdit, setPlannerToEdit] = useState<PlannerType | undefined>(undefined)
  const [formModalVisible, setFormModalVisible] = useState(false);

  const handleModalVisible = (visible: boolean, plannerToEdit?: PlannerType) => {
    setFormModalVisible(visible);
    setPlannerToEdit(plannerToEdit);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3}
                        text={'Planeación'}
                        className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <CalendarComponent onToggleModal={handleModalVisible} />
      </Col>

      {formModalVisible &&
        <PlanningFormModal isEditing={!!plannerToEdit}
                           plannerToEdit={plannerToEdit}
                           onToggleModal={handleModalVisible} />
      }
    </Row>
  );
}

export default Planning;