import React, { useState } from 'react';
import {Row, Col} from 'antd';
import {DownloadOutlined, UploadOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../store/store';
import {getThresholdTemplate} from '../../store/redux/actions/thresholdActions';

// Components
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import ThresholdTable from './ThresholdTable';
import UploadThresholdsModal from './UploadThresholdsModal';

// Constants and Functions
import {getLocalStorageItem} from '../../utils/functions';
import {ROLES} from '../../utils/constants';
import {routes} from "../../utils/routes";


const Thresholds = () => {
  const dispatch = useAppDispatch();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const renderRole = getLocalStorageItem('renderRole');
  const allowedModules = ROLES[renderRole as keyof typeof ROLES].allowedModules as any[];
  const permissions = allowedModules.find(module => module.name === routes.thresholds)!.permissions!;

  const handleModalVisible = (visible: boolean) => {
    setUploadModalVisible(visible);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Umbrales'} className={'font-semibold text-gray-900'} />
      </Col>

      {permissions.canUpload && permissions.canDownload &&
        <Col md={10} sm={10} xs={24} className={'text-right'}>
          <ButtonComponent text={'Cargar archivo'}
                           icon={<UploadOutlined/>}
                           className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                             'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                           onClick={() => handleModalVisible(true)}/>
          <ButtonComponent text={'Descargar plantilla'}
                           icon={<DownloadOutlined/>}
                           className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                             'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                           onClick={() => dispatch(getThresholdTemplate())}/>
        </Col>
      }

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <ThresholdTable />
      </Col>

      {uploadModalVisible && <UploadThresholdsModal onToggleModal={setUploadModalVisible} />}
    </Row>
  );
}

export default Thresholds;