// Libraries
import React, {FunctionComponent} from 'react';
import {Typography} from 'antd';
import PropTypes from 'prop-types';

// Constants
const {Title} = Typography;


export type TitleComponentType = {
  level: 1 | 2 | 3 | 4 | 5 | undefined;
  text: string;
  className?: string;
  onClick?: () => void;
};
const TitleComponent: FunctionComponent<TitleComponentType> = (
  {
    level, text, className, onClick,
  }) => {
  return (
    <Title level={level} className={className} onClick={onClick}>
      {text}
    </Title>
  );
}

TitleComponent.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5]),
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
TitleComponent.defaultProps = {
  level: 1,
  text: 'Título',
  className: '',
  onClick: () => {
    return null;
  }
};

export default TitleComponent;