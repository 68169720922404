// Constants
import {PackageSizeActionType} from '../../../types/packageSizeTypes';
import {packageSizeTypes} from '../types';

const initialState = {
  packageSizesResult: { count: 0, page: 1, size: 10, rows: [] },
}

const packageSizeReducer = (state = initialState, action: PackageSizeActionType) => {
  switch (action.type) {
    case packageSizeTypes.GET_ALL_PACKAGE_SIZES:
      return {
        ...state,
        packageSizesResult: action.packageSizesResult
      };
    default:
      return state;
  }
};

export default packageSizeReducer;
