// Libraries
import React, {useState} from 'react';
import {Row, Form, Modal, Switch, DatePicker, Col, Button, Popover, Divider, Tooltip} from 'antd';
import {useAppDispatch} from '../../store/store';
import {useSelector} from "react-redux";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import ColorPicker from "react-pick-color";
import dayjs from "dayjs";

// Store
import { create } from '../../store/redux/actions/transportActions';
import * as transportSelectors from "../../store/redux/selectors/transportSelectors";

// Types
import {TransportType} from "../../types/transportTypes";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';
import SelectComponent from "../subComponents/SelectComponent";

// Utils
import {DATETIME_FORMAT, DEFAULT_DATETIME_FORMAT} from "../../utils/constants";


type PropsType = {
  onToggleModal: (visible: boolean) => void;
};
const TransportFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const { stores, carriers } = useSelector(transportSelectors.transportParamsSelector);
  const [pickColorOpen, setPickColorOpen] = useState<boolean>(false);
  const [preselectedColor, setPreselectedColor] = useState<string>('#000000');
  const [selectedColor, setSelectedColor] = useState<string>('#E6EAFC');
  const [defaultBackgroundColors, setDefaultBackgroundColors] = useState<string[]>([
      '#E6EAFC', '#FFF3DC', '#FFD5D9', '#DCFFE5', '#FFE0C3', '#DBCCEF', '#FCFFB1', '#E7E7E7'
    ]
  );

  const colorPickerContent = (
    <Col className={'text-right'}>
      <ColorPicker color={selectedColor}
                   hideAlpha={true}
                   onChange={(color) => setPreselectedColor(color.hex)} />

      <ButtonComponent text={'Aceptar'}
                       className={'ant-btn-primary mt-2'}
                       onClick={() => {
                         setDefaultBackgroundColors([...defaultBackgroundColors, preselectedColor]);
                         setPickColorOpen(false);
                       }} />
    </Col>
  );

  const handleSubmit = (transport: TransportType) => {
    const newTransport: TransportType = {
      ...transport,
      startDatetime: dayjs(transport.dateTime).format(DEFAULT_DATETIME_FORMAT),
      dateTime: undefined,
      hexColor: selectedColor,
      withReturn: transport.withReturn ?? false,
      destinations: transport.destinations!.flatMap((destination) => [
        {
          ...destination,
          unloadDateTime: dayjs(destination.unloadDateTime).format(DEFAULT_DATETIME_FORMAT),
        }
      ])
    };
    dispatch(create(newTransport));
    onToggleModal(false);
  }

  return (
    <Modal title={`Crear servicio`}
           width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,

             <ButtonComponent key={'submit'} text={`Crear`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          <FormItemComponent name={'withReturn'}
                             className={'col-span-2'}
                             label={''}
                             required={false}
                             valuePropName={'checked'}
                             child={<Switch checkedChildren={"Con retorno"}
                                            unCheckedChildren={"Sin retorno"} />}
          />

          <FormItemComponent name={'dateTime'}
                             label={'Fecha'}
                             required={true}
                             child={<DatePicker className={'w-full h-[38px] rounded-md'}
                                                allowClear={false}
                                                showTime={true}
                                                format={DATETIME_FORMAT} />}
          />

          <FormItemComponent name={'restrictions'}
                             label={'Restricciones'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'carrierId'}
                             label={'Transportista'}
                             required={true}
                             child={<SelectComponent list={carriers} />}
          />

          <FormItemComponent name={'hexColor'}
                             label={'Color'}
                             required={false}
                             className={'col-span-2'}
                             child={
                               <Col className={'grid grid-cols-8 w-full gap-y-2'}>
                                 {defaultBackgroundColors.map((color) =>
                                   <Button key={color}
                                           style={{ backgroundColor: color }}
                                           onClick={() => setSelectedColor(color)}
                                           className={`visible rounded-[50%] h-[35px] w-[35px] 
                                             ${color === selectedColor && 'border-black'}`
                                           } />
                                 )}

                                 <Popover content={colorPickerContent}
                                          open={pickColorOpen}
                                          trigger={"click"}
                                          placement={'right'}>
                                   <ButtonComponent text={''}
                                                    icon={<PlusOutlined />}
                                                    onClick={() => setPickColorOpen(true)}
                                                    className={'text-grey-dkt-400 rounded-[50%] h-[35px] w-[35px]'} />
                                 </Popover>
                               </Col>
                             }
          />

          <Col className={'col-span-2'}>
            <Divider orientation={"center"} className={'font-normal'}>Destinos</Divider>

            <Form.List name={"destinations"} initialValue={[{ unloadDateTime: '', restrictions: '', destinationStoreId: '' }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Col key={key} className={'grid grid-cols-10 gap-x-2'}>
                      <FormItemComponent name={[name, 'unloadDateTime']}
                                         label={'Fecha'}
                                         required={true}
                                         className={'col-span-3'}
                                         child={<DatePicker className={'w-full h-[38px] rounded-md'}
                                                            allowClear={false}
                                                            showTime={true}
                                                            format={DATETIME_FORMAT} />}
                      />
                      <FormItemComponent name={[name, 'destinationStoreId']}
                                         label={'Tienda destino'}
                                         required={true}
                                         className={'col-span-3'}
                                         child={<SelectComponent list={stores} />}
                      />
                      <FormItemComponent name={[name, 'restrictions']}
                                         label={'Restricciones'}
                                         required={true}
                                         className={'col-span-3'}
                                         child={<InputComponent />}
                      />
                      {fields.length > 1 &&
                        <Col className={'col-span-1 grid'}>
                          <Tooltip title={'Quitar destino'}>
                            <DeleteOutlined className={'text-lg m-auto cursor-pointer hover:text-red-dkt-400'}
                                            onClick={() => remove(name)} />
                          </Tooltip>
                        </Col>
                      }
                    </Col>
                  ))}
                  <Form.Item>
                    <ButtonComponent icon={<PlusOutlined />}
                                     text={'Agregar destino'}
                                     className={'ant-btn-primary'}
                                     onClick={() => add()} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default TransportFormModal;