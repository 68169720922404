// Libraries
import React from 'react';
import {Row, Form, Modal} from 'antd';
import {useAppDispatch} from '../../../store/store';

// Store
import {addReturnDetail} from "../../../store/redux/actions/transportActions";

// Types
import {ReturnDetailType} from "../../../types/transportTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';

// Utils
import {formFields} from "./formFields";


type PropsType = {
  transportId: string | null;
  onToggleModal: (visible: boolean, transportId: string | null) => void;
};
const ReturnDetailFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { transportId, onToggleModal } = props;

  const handleSubmit = (detail: ReturnDetailType) => {
    detail.transportEngineId = transportId ?? '';
    dispatch(addReturnDetail(detail));
    onToggleModal(false, null);
  }

  return (
    <Modal title={`Detalle del retorno`}
           width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'}
                              text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, null)} />,

             <ButtonComponent key={'submit'}
                              text={`Enviar`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          {formFields.map((field) =>
            <FormItemComponent key={field.name}
                               name={field.name}
                               label={field.label}
                               required={true}
                               newRules={[
                                 ({ setFieldValue }) => ({
                                   validator(_, value) {
                                     if (!isNaN(parseInt(value))) {
                                       setFieldValue(field.name, parseInt(value));
                                       return Promise.resolve();
                                     }
                                     return Promise.reject(new Error('El valor debe ser numérico'));
                                   },
                                 })
                               ]}
                              child={<InputComponent />}
            />
          )}
        </Row>
      </Form>
    </Modal>
  );
}

export default ReturnDetailFormModal;