import React, { useState } from 'react';
import {Row, Col} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

// Types
import {PackageSizeType} from "../../types/packageSizeTypes";

// Components and Functions
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import PackageSizesTable from './PackageSizesTable';
import PackageSizesFormModal from './PackageSizesFormModal';


const PackageSizes = () => {
  const [packageSizeToEdit, setPackageSizeToEdit] = useState<PackageSizeType | undefined>(undefined)
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleModalVisible = (visible: boolean, isEditing?: boolean, packageSizeToEdit?: PackageSizeType) => {
    setFormModalVisible(visible);
    setIsEditing(isEditing ?? false);
    setPackageSizeToEdit(packageSizeToEdit ?? undefined);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3}
                        text={'Dimensiones'}
                        className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={10} sm={10} xs={24} className={'text-right'}>
        <ButtonComponent text={'Crear dimensión'}
                         icon={<PlusOutlined />}
                         className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                         onClick={() => handleModalVisible(true)}/>
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <PackageSizesTable onToggleModal={handleModalVisible} />
      </Col>

      {formModalVisible &&
        <PackageSizesFormModal isEditing={isEditing}
                               packageSizeToEdit={packageSizeToEdit}
                               onToggleModal={handleModalVisible} />
      }
    </Row>
  );
}

export default PackageSizes;