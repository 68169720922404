import {ReactNode} from "react";
import {Col} from "antd";
import dayjs from "dayjs";
import {LinkOutlined} from "@ant-design/icons";

// Utils
import {currencyFormat} from "../../utils/functions";
import {DATETIME_FORMAT} from "../../utils/constants";

// Types
import {ShipmentType} from "../../types/shipmentTypes";
import {AllianceType} from "../../types/allianceTypes";
import {StoreType} from "../../types/storeTypes";


export const tableColumns = (actionButtons: (args: any) => ReactNode) => {
  return [
    {
      title: <Col className={'text-center'}>Guía</Col>,
      dataIndex: "externalGuideId",
      key: "externalGuideId",
      width: "100px",
      ellipsis: true,
      render: (text: string) => <Col className={'text-center'}>{text}</Col>
    },
    {
      title: <Col className={'text-center'}>Estado de entrega</Col>,
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      width: "100px",
      ellipsis: true,
      render: (text: string) => <Col className={'text-center'}>{text}</Col>
    },
    {
      title: <Col className={'text-center'}>Alianza</Col>,
      dataIndex: "alliance",
      key: "alliance",
      width: "110px",
      ellipsis: true,
      render: (alliance: AllianceType) => <Col className={'text-center'}>{alliance.name}</Col>
    },
    {
      title: <Col className={'text-center'}>Centro</Col>,
      dataIndex: "store",
      key: "store",
      width: "150px",
      ellipsis: true,
      render: (store: StoreType) => <Col className={'text-center'}>{`${store.name} - ${store.code}`}</Col>
    },
    {
      title: <Col className={'text-center'}>Guía externa</Col>,
      dataIndex: "externalGuideUrl",
      key: "externalGuideUrl",
      width: "70px",
      ellipsis: true,
      render: (text: string) => <Col className={'text-center'}>
        <a title={'Ir a URL'} href={text} target={"_blank"} rel={"noreferrer"}><LinkOutlined /></a>
      </Col>
    },
    {
      title: <Col className={'text-center'}>Novedad externa</Col>,
      dataIndex: "externalNovelty",
      key: "externalNovelty",
      width: "150px",
      ellipsis: true,
      render: (text: string) => <Col className={'text-center'}>{text}</Col>
    },
    {
      title: <Col className={'text-center'}>Fecha de novedad</Col>,
      dataIndex: "noveltyDate",
      key: "noveltyDate",
      width: "100px",
      ellipsis: true,
      render: (text: string) => <Col className={'text-center'}>{dayjs(text).format(DATETIME_FORMAT)}</Col>
    },
    {
      title: <Col className={'text-center'}>Documento del contacto</Col>,
      dataIndex: "contactDocument",
      key: "contactDocument",
      width: "150px",
      ellipsis: true,
      render: (text: string) => <Col className={'text-center'}>{text}</Col>
    },
    {
      title: <Col className={'text-center'}>Valor declarado</Col>,
      dataIndex: "declaredValue",
      key: "declaredValue",
      width: "100px",
      ellipsis: true,
      render: (text: string) => <Col className={'text-center'}>{`$ ${currencyFormat(text)}`}</Col>
    },
    {
      title: <Col className={'text-center'}>Seguimiento externo</Col>,
      dataIndex: "externalTrackingUrl",
      key: "externalTrackingUrl",
      width: "120px",
      ellipsis: true,
      render: (text: string) => <Col className={'text-center'}>
        <a title={'Ir a URL'} href={text} target={"_blank"} rel={"noreferrer"}><LinkOutlined /></a>
      </Col>
    },
    {
      title: <Col className={'text-center'}>Artículos</Col>,
      dataIndex: "actions",
      key: "actions",
      width: "80px",
      fixed: "right",
      render: (_: any, shipment: ShipmentType) => <Col className={'text-center'}>{actionButtons(shipment.packageItems)}</ Col>,
    }
  ];
};