import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {PackageSizeType} from "../types/packageSizeTypes";


function getAll(data: PaginationType) {
  return request({
    url: 'packageSize/getAll',
    method: 'GET',
    params: data
  });
}
function create(packageSize: PackageSizeType) {
  return request({
    url: 'packageSize/create',
    method: 'POST',
    data: packageSize
  });
}
function update(packageSize: PackageSizeType) {
  return request({
    url: 'packageSize/update',
    method: 'PUT',
    data: packageSize
  });
}
function remove(packageSizeId: string) {
  return request({
    url: `packageSize/delete/${packageSizeId}`,
    method: 'DELETE',
  });
}

export default {
  getAll,
  create,
  update,
  remove
}