// Libraries
import React, {useState} from 'react';
import {Col, Form, Modal, Popconfirm, Steps} from 'antd';
import dayjs from "dayjs";
import {RcFile} from "antd/lib/upload";

// Types
import {TransportDetailType} from "../../../types/transportTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import Details from "./Details";
import Files from "./Files";
import SealNumbers from "./SealNumbers";

// Utils
import {DEFAULT_DATETIME_FORMAT} from "../../../utils/constants";

// Store
import {useAppDispatch} from '../../../store/store';
import {addTransportDetail} from "../../../store/redux/actions/transportActions";


type PropsType = {
  destinations: string[];
  transportId: string | null;
  onToggleModal: (visible: boolean, transportId: string | null, destinations: string[]) => void;
};
const TransportFormModal = (props: PropsType) => {
  const [form] = Form.useForm<TransportDetailType>();
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState<TransportDetailType>();
  const [currentStep, setCurrentStep] = useState(0);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const { destinations, transportId, onToggleModal } = props;
  const steps = [
    { title: 'Detalles', content: <Details /> },
    { title: 'Números de precinto', content: <SealNumbers destinations={destinations} /> },
    { title: 'Archivos', content: <Files fileList={fileList} setFileList={setFileList} /> },
  ];
  const items = steps.map(item => ({ key: item.title, title: item.title }));

  const nextStep = () => {
    if (currentStep === 0) {
      setFormValues(form.getFieldsValue());
    } else if (currentStep === 1) {
      const sealNumbers = form.getFieldsValue().sealNumbers;
      const newValues = {
        ...formValues,
        sealNumbers: sealNumbers,
      };
      setFormValues(newValues as TransportDetailType);
    }
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = () => {
    const newValues = formValues!;
    newValues.transportEngineId = transportId ?? '';
    newValues.vehicleAnnouncementTime = dayjs(newValues.vehicleAnnouncementDate).format(DEFAULT_DATETIME_FORMAT);
    newValues.vehicleAnnouncementDate = undefined;
    newValues.loadingStartTime = dayjs(newValues.loadingStartDate).format(DEFAULT_DATETIME_FORMAT);
    newValues.loadingStartDate = undefined;
    newValues.loadingEndTime = dayjs(newValues.loadingEndDate).format(DEFAULT_DATETIME_FORMAT);
    newValues.loadingEndDate = undefined;
    newValues.sealNumbers = formValues?.sealNumbers ?? [];

    dispatch(addTransportDetail(newValues, fileList));
    onToggleModal(false, null, []);
  }

  return (
    <Modal title={`Agregar detalle del servicio`}
           width={700}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'}
                              text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, null, [])} />,
             currentStep > 0 && (
               <ButtonComponent key={'previous'}
                                text={'Anterior'}
                                className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                  'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                                onClick={() => prevStep()} />
             ),
             currentStep < steps.length - 1 && (
               <ButtonComponent key={'next'}
                                text={`Siguiente`}
                                className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                  'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                                onClick = {() => form.submit()}/>
             ),
             currentStep === steps.length - 1 && (
               <Popconfirm key={'submit'}
                           title={'¿Confirma el envío de la información?'}
                           okText={'Confirmar'}
                           onConfirm={() => form.submit()}>
                 <ButtonComponent text={`Enviar`}
                                  className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                    'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'} />
               </Popconfirm>
             ),
           ]}>
      <>
        <Steps current={currentStep}
               items={items} />

        <Form layout={"vertical"}
              form={form}
              onFinish={currentStep < steps.length - 1 ? nextStep : handleSubmit}>
          <Col className={"mt-4 py-4"}>
            {steps[currentStep].content}
          </Col>
        </Form>
      </>
    </Modal>
  );
}

export default TransportFormModal;