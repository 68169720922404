// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import ctnServices from '../../../api/ctnServices';

// Types
import {ctnTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {CtnType} from '../../../types/ctnTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Constants
import {getBaseUrl} from '../../../api/baseURL';


export const getAllCTN = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  ctnServices.getAll(data)
    .then((response) => {
      dispatch({
        type: ctnTypes.GET_ALL_CTN,
        ctnResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const uploadCtnFile = (formData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  ctnServices.upload(formData)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllCTN({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getCtnTemplate = () => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  ctnServices.getTemplate()
    .then((response) => {
      window.open(getBaseUrl() + response.data.excelUrl);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const createCTN = (ctn: CtnType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  ctnServices.create(ctn)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllCTN({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const updateCTN = (ctn: CtnType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  ctnServices.update(ctn)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllCTN({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const removeCTN = (ctnId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  ctnServices.remove(ctnId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllCTN({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}

export const deleteAllByContainer = (containerNumber: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  ctnServices.deleteAllByContainer(containerNumber)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllCTN({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}