import React, {useEffect} from 'react';
import {Col, Divider, Space} from "antd";

// Components
import FormItemComponent from "../../subComponents/FormItemComponent";
import InputComponent from "../../subComponents/InputComponent";
import Account from "../Account";

// Types
import {SessionType} from "../../../types/sessionTypes";

// Utils
import {routes} from "../../../utils/routes";
import {getWindowInformation, isEmpty} from "../../../utils/functions";

// Store
import {useAppDispatch} from "../../../store/store";
import {confirmEmail, login} from "../../../store/redux/actions/authActions";


const Login = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = getWindowInformation();
  const isSupplierUser = pathname === routes.supplierAccount.login;
  const urlParams = new URLSearchParams(search);
  const code = urlParams.get('code');

  useEffect(() => {
    if(!isEmpty(code)) {
      dispatch(confirmEmail(code ?? '', isSupplierUser ? routes.supplierAccount.login : routes.carrierAccount.login));
    }
  }, [code])

  const handleSubmit = (values: SessionType) => {
    values.isSupplierUser = isSupplierUser;
    dispatch(login(values));
  }

  return (
    <Account title={'Inicia Sesión'}
             buttonText={'Ingresar'}
             handleSubmit={handleSubmit}
             formContent={
               <>
                 <FormItemComponent name={'email'}
                                    label={'Correo'}
                                    required={true}
                                    child={<InputComponent className={'focus:outline-none focus:ring-grey-dkt-300 ' +
                                      'focus:border-grey-dkt-300 focus:border-0'} />}
                 />

                 <FormItemComponent name={'password'}
                                    label={'Contraseña'}
                                    required={true}
                                    child={<InputComponent type={'password'} />}
                 />

                 <Col className={'text-center mt-2'}>
                   <Space direction={'horizontal'} split={<Divider type={'vertical'} className={'bg-blue-dkt-200'} />}>
                     <a href={isSupplierUser ? routes.supplierAccount.forgotPassword : routes.carrierAccount.forgotPassword}
                        className={'text-blue-dkt-500 hover:underline font-semibold'}>Olvidé contraseña</a>
                     <a href={isSupplierUser ? routes.supplierAccount.register : routes.carrierAccount.register}
                        className={'text-blue-dkt-500 hover:underline font-semibold'}>Registrarme</a>
                   </Space>
                 </Col>
               </>
             } />
  );
}

export default Login;