import React, { useState } from 'react';
import {Row, Col} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

// Types
import {AllianceType} from "../../types/allianceTypes";

// Components and Functions
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import AllianceTable from './AllianceTable';
import AllianceFormModal from './AllianceFormModal';


const Alliances = () => {
  const [allianceToEdit, setAllianceToEdit] = useState<AllianceType | undefined>(undefined)
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleModalVisible = (visible: boolean, isEditing?: boolean, allianceToEdit?: AllianceType) => {
    setFormModalVisible(visible);
    setIsEditing(isEditing ?? false);
    setAllianceToEdit(allianceToEdit ?? undefined);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3}
                        text={'Alianzas'}
                        className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={10} sm={10} xs={24} className={'text-right'}>
        <ButtonComponent text={'Crear alianza'}
                         icon={<PlusOutlined />}
                         className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                         onClick={() => handleModalVisible(true)}/>
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <AllianceTable onToggleModal={handleModalVisible} />
      </Col>

      {formModalVisible &&
        <AllianceFormModal isEditing={isEditing}
                           allianceToEdit={allianceToEdit}
                           onToggleModal={handleModalVisible} />
      }
    </Row>
  );
}

export default Alliances;