import React, {useState} from 'react';
import {Row, Tabs} from 'antd';

// Components
import Carriers from "./carriers/Carriers";
import CarrierUsers from "./carrierUsers/CarrierUsers";


const CarrierTabs = () => {
  const [activeKey, setActiveKey] = useState<string>("1");

  return (
    <Row>
      <Tabs key={activeKey}
            defaultActiveKey={activeKey}
            className={'overflow-x-auto'}
            onChange={setActiveKey}>
        <Tabs.TabPane tab={"Transportistas"} key={"1"}>
          <Carriers />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Usuarios"} key={"2"}>
          <CarrierUsers />
        </Tabs.TabPane>
      </Tabs>
    </Row>
  );
}

export default CarrierTabs;