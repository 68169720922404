import React, {useState} from 'react';
import {Row, Col} from 'antd';
import {SyncOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../../store/store';
import {sync} from '../../../store/redux/actions/userStoreMapActions';

// Components
import TitleComponent from '../../subComponents/TitleComponent';
import ButtonComponent from '../../subComponents/ButtonComponent';
import UserStoreMapsTable from './UserStoreMapsTable';
import UserStoreMapsFormModal from "./UserStoreMapsFormModal";

// Types
import {UserStoreMapType} from "../../../types/userStoreMapTypes";


const UserStoreMaps = () => {
  const dispatch = useAppDispatch();
  const [userStoreMapToEdit, setUserStoreMapToEdit] = useState<UserStoreMapType | undefined>(undefined)
  const [formModalVisible, setFormModalVisible] = useState(false);

  const handleSync = () => {
    dispatch(sync());
  }
  const handleModalVisible = (visible: boolean, userStoreMapToEdit?: UserStoreMapType) => {
    setFormModalVisible(visible);
    setUserStoreMapToEdit(userStoreMapToEdit);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3}
                        text={'Usuarios'}
                        className={'font-semibold text-gray-900'} />
      </Col>
      <Col md={10} sm={10} xs={24} className={'text-right'}>
        <ButtonComponent text={'Sincronizar'}
                         icon={<SyncOutlined />}
                         className={'ant-btn-primary'}
                         onClick={handleSync}/>
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <UserStoreMapsTable onToggleModal={handleModalVisible} />
      </Col>

      {formModalVisible &&
        <UserStoreMapsFormModal isEditing={!!userStoreMapToEdit}
                                userStoreMapToEdit={userStoreMapToEdit}
                                onToggleModal={handleModalVisible} />
      }
    </Row>
  );
}

export default UserStoreMaps;