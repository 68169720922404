// Libraries
import React, {useEffect, useState} from 'react';
import {Row, Form, Modal, Switch} from 'antd';
import {useAppDispatch} from '../../store/store';
import {useSelector} from 'react-redux';
import ColorPicker from 'react-pick-color';

// Store
import * as allianceSelectors from '../../store/redux/selectors/allianceSelectors';
import { create, update } from '../../store/redux/actions/allianceActions';

// Types
import {AllianceType} from "../../types/allianceTypes";

// Components
import SelectComponent from '../subComponents/SelectComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type PropsType = {
  isEditing?: boolean;
  allianceToEdit?: AllianceType;
  onToggleModal: (visible: boolean) => void;
};
const AllianceFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [selectedColor, setSelectedColor] = useState('#000');
  const { isEditing, allianceToEdit, onToggleModal } = props;
  const params = useSelector(allianceSelectors.allianceParamsSelector);

  useEffect(() => {
    if (isEditing) {
      setSelectedColor(allianceToEdit!.color);
    }
  }, []);

  const handleSubmit = (alliance: AllianceType) => {
    alliance.color = selectedColor;

    if(isEditing){
      alliance.allianceId = allianceToEdit!.allianceId;

      dispatch(update(alliance));
    } else {
      alliance.isActive = true;

      dispatch(create(alliance));
    }
    onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} alianza`}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            initialValues={allianceToEdit ?? {}}
            onFinish = {handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          {isEditing &&
            <FormItemComponent name={'isActive'}
                               className={'col-span-2'}
                               label={''}
                               required={false}
                               valuePropName={'checked'}
                               child={<Switch checkedChildren={"Activa"}
                                              unCheckedChildren={"Inactiva"} />}
            />
          }

          <FormItemComponent name={'name'}
                             label={'Nombre'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'type'}
                             label={'Tipo'}
                             required={true}
                             child={<SelectComponent list={params} />}
          />

          <span>
            <FormItemComponent name={'color'}
                               label={'Color (hexadecimal)'}
                               className={'[&>div>div.ant-form-item-control]:h-0 mb-1'}
                               required={false}
                               child={<span></span>}
            />
            <ColorPicker color={selectedColor}
                         onChange={(color) => setSelectedColor(color.hex)} />
          </span>
        </Row>
      </Form>
    </Modal>
  );
}

export default AllianceFormModal;