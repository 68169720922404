import {StoreType} from '../../../types/sharedTypes';


export const orderDetailSelector = (state: StoreType) => {
  return state.orderReducer.orderDetail;
}

export const allOrdersSelector = (state: StoreType) => {
  return state.orderReducer.ordersResult;
}
