// Types
import {carrierUserTypes} from '../types';
import {CarrierUsersActionType} from '../../../types/carrierUserTypes';

const initialState = {
  params: {
    carriers: [],
  },
  carrierUsersResult: {
    count: 0,
    page: 1,
    size: 10,
    rows: []
  },
}

const carrierUserReducer = (state = initialState, action: CarrierUsersActionType) => {
  switch (action.type) {
    case carrierUserTypes.GET_CARRIER_USER_PARAMS:
      return {
        ...state,
        params: action.params
      };
    case carrierUserTypes.GET_ALL_CARRIER_USERS:
      return {
        ...state,
        carrierUsersResult: action.carrierUsersResult
      };
    default:
      return state;
  }
};

export default carrierUserReducer;
