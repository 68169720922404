// Libraries
import {Row, Form, Modal, Switch} from 'antd';
import {useAppDispatch} from '../../store/store';

// Store
import { create, update } from '../../store/redux/actions/supplierActions';

// Types
import {SupplierType} from '../../types/supplierTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type PropsType = {
  isEditing?: boolean;
  supplierToEdit?: SupplierType;
  onToggleModal: (visible: boolean) => void;
};
const SuppliersFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isEditing, supplierToEdit, onToggleModal } = props;

  const handleSubmit = (supplier: SupplierType) => {
    if(isEditing){
      supplier.supplierUserId = supplierToEdit!.supplierUserId;

      dispatch(update(supplier));
    } else {
      supplier.isActive = true;

      dispatch(create(supplier));
    }
    onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} proveedor`}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            initialValues={supplierToEdit ?? {}}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          {isEditing &&
            <FormItemComponent name={'isActive'}
                               className={'col-span-2'}
                               label={''}
                               required={false}
                               valuePropName={'checked'}
                               child={<Switch checkedChildren={"Activo"}
                                              unCheckedChildren={"Inactivo"} />}
            />
          }

          <FormItemComponent name={'name'}
                             label={'Nombre'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'email'}
                             label={'Correo'}
                             required={true}
                             child={<InputComponent />}
          />

        </Row>
      </Form>
    </Modal>
  );
}

export default SuppliersFormModal;