import React, {FunctionComponent, useEffect, useState} from 'react';
import {Col, Popconfirm, Space} from 'antd';
import PropTypes from 'prop-types';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../store/store';
import * as remissionSelectors from '../../store/redux/selectors/remissionSelectors';
import {getAllRemissions, removeRemission} from '../../store/redux/actions/remissionActions';

// Types
import {RemissionType} from '../../types/remissionTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';
import RemissionFormModal from './RemissionFormModal';
import DownloadRemissionsFormModal from './DownloadRemissionFormModal';
import FiltersComponent from '../subComponents/FiltersComponent';

// Functions and Constants
import {getFilterParameters, getTableColumns} from '../../utils/componentsUtils';


type RemissionTableType = {
  downloadModalVisible: boolean;
  onToggleModal: (visible: boolean) => void;
}
const RemissionTable: FunctionComponent<RemissionTableType> = ({
  downloadModalVisible,
  onToggleModal
}) => {
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10,
    filters: {}
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [remissionToEdit, setRemissionToEdit] = useState<RemissionType | null>();
  const dispatch = useAppDispatch();
  const { rows, count, size, page } = useSelector(remissionSelectors.allRemissionsSelector);
  const filterParameters = getFilterParameters('remission');

  useEffect(() => {
    dispatch(getAllRemissions(paginationData));
  }, [paginationData]);

  const handleFormModal = (visible: boolean, remissionId?: string) => {
    setFormModalVisible(visible);
    if(visible){
      const remissionToEdit = JSON.parse(JSON.stringify(rows.find(remission => remission.remissionId === remissionId)));
      setRemissionToEdit(remissionToEdit);
    } else {
      setRemissionToEdit(null);
    }
  }

  const actionButtons = (remissionId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, remissionId)} />
        <Popconfirm title={'¿Deseas borrar esta remisión?'} okText={'Confirmar'} cancelText={'Cancelar'}
                    onConfirm={() => dispatch(removeRemission(remissionId))}>
          <DeleteOutlined className={'cursor-pointer hover:text-red-dkt-400'} />
        </Popconfirm>
      </Space>
    )
  };

  return (
    <>
      <Col md={24} xs={24} className={'mt-5'}>
        <FiltersComponent filterParameters={filterParameters}
                          onChangeFilters={(values) => setPaginationData({ page, size, filters: values })} />
      </Col>

      <TableComponent pageSize={size} currentPage={page} total={count}
                      filters={paginationData.filters ?? {}} 
                      dataSource={rows} scrollX={3800} rowKey={'remissionId'}
                      columns={getTableColumns('remission', actionButtons)}
                      onChangePage={setPaginationData} />

      {formModalVisible &&
        <RemissionFormModal remissionToEdit={remissionToEdit ?? null}
                          onToggleModal={handleFormModal} />
      }
      {downloadModalVisible && 
        <DownloadRemissionsFormModal shippingNumber={paginationData.filters?.shippingNumber ?? ''} 
                                    onToggleModal={onToggleModal} />}
    </>
  );
}

RemissionTable.propTypes = {
  downloadModalVisible: PropTypes.bool.isRequired,
  onToggleModal: PropTypes.func.isRequired
};
RemissionTable.defaultProps = {
  downloadModalVisible: false,
  onToggleModal: () => {
    return;
  }
};

export default RemissionTable;