export const formFields = [
  { name: "pallets", type: "number", label: "Número de pallets" },
  { name: "dollies", type: "number", label: "Dollies" },
  { name: "lids", type: "number", label: "Tapas" },
  { name: "baskets", type: "number", label: "Canastas" },
  { name: "magnus", type: "number", label: "Magnus" },
  { name: "interstoreShipments", type: "number", label: "Intertiendas" },
  { name: "carReturns", type: "number", label: "Retornos CAR" },
  { name: "claims", type: "number", label: "Reclamaciones" },
  { name: "advertising", type: "number", label: "Publicidad" },
];