import request from './requestWrapper';

// Types
import {DuplicateEventType, PlannerType} from "../types/plannerTypes";


function getParams() {
  return request({
    url: 'planner/getParams',
    method: 'GET',
  });
}
function getAllByMonth(month: number, year: number) {
  return request({
    url: `planner/getAllByMonth?month=${month}&year=${year}`,
    method: 'GET',
  });
}
function create(data: PlannerType) {
  return request({
    url: `planner/create`,
    method: 'POST',
    data: data
  });
}
function duplicateEvents(data: DuplicateEventType) {
  return request({
    url: `planner/duplicateEvents`,
    method: 'POST',
    data: data
  });
}
function update(data: PlannerType) {
  const id = data.plannerId;
  data.plannerId = undefined;
  return request({
    url: `planner/update/${id}`,
    method: 'PUT',
    data: data
  });
}
function remove(plannerId: string) {
  return request({
    url: `planner/delete/${plannerId}`,
    method: 'DELETE',
  });
}

export default {
  getParams, getAllByMonth,
  duplicateEvents, create,
  update,
  remove
}