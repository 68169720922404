import React, { useEffect, useState, useCallback } from 'react';
import {Row, Select, Calendar, Space, Col, Switch, Alert} from 'antd';
import moment, {Moment} from 'moment';
import dayjs from "dayjs";
import 'dayjs/locale/es';
import { useSelector } from "react-redux";
import {PlusOutlined, TruckOutlined } from "@ant-design/icons";

// Store
import { useAppDispatch } from "../../store/store";
import * as plannerSelectors from "../../store/redux/selectors/plannerSelectors";
import { getParams } from "../../store/redux/actions/plannerActions";

// Types
import { PlannerType } from "../../types/plannerTypes";

// Components
import ButtonComponent from "../subComponents/ButtonComponent";
import DuplicateFormModal from "./DuplicateFormModal";

dayjs.locale('es');

const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (_, index) => index + start);

type PropsType = {
  onToggleModal: (visible: boolean, plannerToEdit?: PlannerType) => void;
};

const CalendarComponent = ({ onToggleModal }: PropsType) => {
  const dispatch = useAppDispatch();
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [duplicateEvents, setDuplicateEvents] = useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | undefined>(undefined);
  const [provisionalEndDate, setProvisionalEndDate] = useState<Moment | undefined>(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | undefined>(undefined);
  const [events, setEvents] = useState<any[]>([]);
  const [highlightedEventId, setHighlightedEventId] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<number>(dayjs().month());
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());

  const stores = useSelector(plannerSelectors.plannerParamsSelector)?.stores;
  const planner = useSelector(plannerSelectors.allPlannerSelector);

  useEffect(() => {
    dispatch(getParams(selectedMonth + 1, selectedYear));
  }, [selectedMonth, selectedYear, dispatch]);

  useEffect(() => {
    const newEvents = planner.flatMap(element => [
      {
        id: element.plannerId,
        dateTime: element.startDatetime,
        color: element.hexColor,
        withReturn: element.withReturn,
        isUnload: false,
        store: '',
        hasService: !!element.transportEngineId
      },
      ...element.unloadsInfo.map(unload => ({
        id: element.plannerId,
        dateTime: unload.endDatetime,
        color: element.hexColor,
        isUnload: true,
        store: stores?.find(store => store.id === unload.storeId)?.value || '',
        hasService: !!element.transportEngineId
      }))
    ]);

    setEvents(newEvents);
  }, [planner, stores]);

  const handleFormModal = useCallback((visible: boolean, plannerId?: string) => {
      const plannerToEdit = planner.find(p => p.plannerId === plannerId);
      onToggleModal(visible, plannerToEdit);
    },
    [planner, onToggleModal]
  );

  const getHeaderRender = useCallback(({ value, onChange }: any) => {
    const handleChange = (type: 'month' | 'year', newValue: number) => {
      const now = value.clone()[type](newValue);
      onChange(now);
      type === 'month' ? setSelectedMonth(newValue) : setSelectedYear(newValue);
    };

    const renderSelect = (type: 'month' | 'year', range: number[]) => (
      <Select size={"middle"}
              dropdownMatchSelectWidth={false}
              value={type === 'month' ? selectedMonth : selectedYear}
              onChange={newValue => handleChange(type, newValue)}
      >
        {range.map(number => (
          <Select.Option key={number} value={number}>
            {type === 'month' ? dayjs().month(number).format('MMMM') : number}
          </Select.Option>
        ))}
      </Select>
    );

    return (
      <Row className={"grid grid-cols-2 mb-5 gap-y-4"}>
        <Col className={duplicateEvents ? 'col-span-1' : 'col-span-2'}>
          <Switch className={'w-fit'}
                  checked={duplicateEvents}
                  checkedChildren={'Duplicado activo'}
                  unCheckedChildren={'Duplicado inactivo'}
                  onChange={() => handleDuplicateEvents('setMode')} />
        </Col>

        {duplicateEvents &&
          <>
            <p className={'text-xs text-right'}>
              <b>Duplicado activo:</b> no podrá editar ni agregar un evento.
            </p>

            <Alert className={'col-span-2 p-2'}
                   description={'Seleccione los días que desea duplicar'} />
          </>
        }

        <Col>
          {renderSelect('month', range(0, 12))}
          {renderSelect('year', range(selectedYear - 10, selectedYear + 10))}
        </Col>

        {!duplicateEvents &&
          <Col className={"text-right"}>
            <ButtonComponent text={"Agregar"}
                             icon={<PlusOutlined />}
                             className={"ant-btn-primary"}
                             onClick={() => handleFormModal(true)}
            />
          </Col>
        }
      </Row>
    );
  }, [selectedMonth, selectedYear, handleFormModal, duplicateEvents]);

  const dateCellRender = useCallback((value: moment.Moment) => {
      const day = moment(value).date();
      const month = moment(value).month();
      let backgroundColor = 'bg-[transparent]';
      if (duplicateEvents && selectedStartDate){
        if (value >= selectedStartDate && value <= provisionalEndDate!){
          backgroundColor = 'bg-[#e6f7ff]';
        }
      }

      const list = events
        .filter(event => moment(event.dateTime).date() === day && moment(event.dateTime).month() === month)
        .sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1));

      return (
        <Col className={`ant-picker-cell-inner ant-picker-calendar-date py-1 px-2 before:hidden 
          ${backgroundColor}`}
             onMouseOver={() => handleDuplicateEvents('provisionalDate', value)}
             onClick={() => handleDuplicateEvents('setDate', value)}>
          <Col className="ant-picker-calendar-date-value">{day}</Col>
          <Col className="ant-picker-calendar-date-content">
            <Space direction={"vertical"} size={"small"} className={"min-w-full"}>
              {list.map(item => {
                const time = moment(item.dateTime).format('HH:mm');

                return (
                  <Col key={item.id + item.dateTime}
                     style={{ backgroundColor: item.color }}
                     onMouseOver={() => setHighlightedEventId(item.id)}
                     onMouseLeave={() => setHighlightedEventId('')}
                     className={`w-full whitespace-nowrap px-[6px] py-[2px] rounded-lg text-xs text-grey-dkt-900 border 
                   border-grey-dkt-300 ${item.id === highlightedEventId && 'font-semibold border-blue-dkt-500 shadow-sm shadow-grey-dkt-600'}
                 `}
                     onClick={() => duplicateEvents ? null : handleFormModal(true, item.id)}
                  >
                    <Col>
                      <p>
                        {item.hasService && <TruckOutlined className={'mr-1 text-blue-dkt-600'} />}
                        {`${time}: ${item.isUnload ? 'Descarga' : 'Carga'}`}
                      </p>
                    </Col>

                    {`${item.isUnload ? item.store : `[${item.withReturn ? 'Con' : 'Sin'} retorno]`}`}
                  </Col>
                );
              })}
            </Space>
          </Col>
        </Col>
      );
    },
    [events, highlightedEventId, handleFormModal, duplicateEvents, selectedStartDate, provisionalEndDate]
  );

  const handleDuplicateEvents = (action: 'provisionalDate' | 'setDate' | 'setMode', date?: Moment) => {
    if (duplicateEvents || action === 'setMode') {
      switch (action) {
        case 'setMode':
          duplicateEvents && setSelectedStartDate(undefined);
          duplicateEvents && setSelectedEndDate(undefined);
          setDuplicateEvents(!duplicateEvents);
          break;
        case "setDate":
          if ((!selectedStartDate || date! < selectedStartDate) || (selectedStartDate && selectedEndDate)) {
            setSelectedStartDate(date);
            setProvisionalEndDate(date);
            setSelectedEndDate(undefined);
          } else if (selectedStartDate && date! > selectedStartDate) {
            setSelectedEndDate(date);
            setShowDuplicateModal(true);
          }
          break;
        case "provisionalDate":
          if (selectedStartDate && !selectedEndDate) {
            setProvisionalEndDate(date);
          }
          break;
      }
    }
  }

  return (
    <>
      <Calendar mode={"month"}
                dateFullCellRender={dateCellRender}
                headerRender={getHeaderRender}
                className={`[&>div>div>div>table>tbody>tr>td>div>div.ant-picker-calendar-date-content]:py-1`}
                onPanelChange={date => {
                  setSelectedMonth(date.month());
                  setSelectedYear(date.year());
                }}
      />

      {showDuplicateModal &&
        <DuplicateFormModal selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            startDatetime={selectedStartDate!}
                            endDatetime={selectedEndDate!}
                            setDuplicateEvents={() => setDuplicateEvents(false)}
                            onToggleModal={(visible) => {
                              setShowDuplicateModal(visible);
                              handleDuplicateEvents('setMode');
                            }} />
      }
    </>
  );
};

export default CalendarComponent;
