import React, {useEffect, useState} from 'react';
import {Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../../store/store';
import * as userStoreMapSelectors from '../../../store/redux/selectors/userStoreMapSelectors';
import {getParams} from '../../../store/redux/actions/userStoreMapActions';

// Types
import {UserStoreMapType} from '../../../types/userStoreMapTypes';
import {PaginationType} from "../../../types/sharedTypes";

// Components and Subcomponents
import TableComponent from '../../subComponents/TableComponent';

// Functions and Constants
import {getTableColumns} from '../../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, userStoreMapToEdit?: UserStoreMapType) => void;
}
const UserStoreMapsTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1, size: 10
  });
  const [list, setList] = useState<UserStoreMapType[]>([]);
  const { rows, count, size, page } = useSelector(userStoreMapSelectors.allUserStoreMapsSelector);
  const { stores } = useSelector(userStoreMapSelectors.userStoreMapParamsSelector);

  useEffect(() => {
    dispatch(getParams(paginationData));
  }, [paginationData]);

  useEffect(() => {
    const newList: UserStoreMapType[] = rows.flatMap((userStoreMap) => [
      {
        ...userStoreMap,
        storeName: stores.find((store) => store.id === userStoreMap.storeId)?.value as string,
      }
    ]);

    setList(newList);
  }, [stores, rows]);

  const handleFormModal = (visible: boolean, userStoreMapId?: string) => {
    const userStoreMapToEdit = rows.find(userStoreMap => userStoreMap.userStoreMapId === userStoreMapId);

    onToggleModal(visible, userStoreMapToEdit);
  }

  const actionButtons = (userStoreMapId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, userStoreMapId)} />
      </Space>
    )
  };

  return (
    <>
      <TableComponent pageSize={size}
                      currentPage={page}
                      total={count}
                      dataSource={list}
                      scrollX={700}
                      rowKey={'userStoreMapId'}
                      columns={getTableColumns('userStoreMaps', actionButtons)}
                      onChangePage={(pagination) => {
                        setPaginationData({ page: pagination.page, size: pagination.size });
                      }} />
    </>
  );
}

export default UserStoreMapsTable;
