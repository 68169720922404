// Constants
import {SupplierActionType} from '../../../types/supplierTypes';
import {supplierTypes} from '../types';

const initialState = {
  suppliersResult: { count: 0, page: 1, size: 10, rows: [] },
}

const supplierReducer = (state = initialState, action: SupplierActionType) => {
  switch (action.type) {
    case supplierTypes.GET_ALL_SUPPLIERS:
      return {
        ...state,
        suppliersResult: action.suppliersResult
      };
    default:
      return state;
  }
};

export default supplierReducer;
