// Libraries
import React from 'react';
import {Row, Form, Modal, Switch} from 'antd';
import {useAppDispatch} from '../../../store/store';

// Store
import { update } from '../../../store/redux/actions/storeMapActions';

// Types
import {StoreMapType} from "../../../types/storeMapTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';


type PropsType = {
  isEditing?: boolean;
  storeMapToEdit?: StoreMapType;
  onToggleModal: (visible: boolean) => void;
};
const StoreMapsFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isEditing, storeMapToEdit, onToggleModal } = props;

  const handleSubmit = (storeMap: StoreMapType) => {
    const newStoreMap: StoreMapType = {
      isMain: storeMap.isMain,
      prefix: storeMap.prefix,
    }
    dispatch(update(storeMapToEdit!.storeMapId!, newStoreMap));
    onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} tienda`}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            initialValues={storeMapToEdit ?? {}}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          <FormItemComponent name={'isMain'}
                             className={'col-span-2'}
                             label={''}
                             required={false}
                             valuePropName={'checked'}
                             child={<Switch checkedChildren={"Principal"}
                                            unCheckedChildren={"No principal"} />}
          />

          <FormItemComponent name={'name'}
                             label={'Nombre'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'code'}
                             label={'Código'}
                             required={false}
                             child={<InputComponent disabled={true}
                                                    readOnly={true} />}
          />

          <FormItemComponent name={'address'}
                             label={'Dirección'}
                             required={false}
                             child={<InputComponent disabled={true}
                                                    readOnly={true} />}
          />

          <FormItemComponent name={'prefix'}
                             label={'Prefijo'}
                             required={true}
                             child={<InputComponent />}
          />
        </Row>
      </Form>
    </Modal>
  );
}

export default StoreMapsFormModal;
