import React, {useState} from 'react';
import {Row, Col} from 'antd';

// Components and Functions
import TitleComponent from '../subComponents/TitleComponent';
import OrdersTable from './OrdersTable';
import OrdersFormModal from "./OrdersFormModal";


const Orders = () => {
  const [orderNumber, setOrderNumber] = useState<string>('');
  const [formModalVisible, setFormModalVisible] = useState(false);

  const handleModalVisible = (visible: boolean, orderNumber: string) => {
    setFormModalVisible(visible);
    setOrderNumber(orderNumber);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3}
                        text={'Órdenes'}
                        className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <OrdersTable onToggleModal={handleModalVisible} />
      </Col>

      {formModalVisible &&
        <OrdersFormModal orderNumber={orderNumber}
                         onToggleModal={handleModalVisible} />
      }
    </Row>
  );
}

export default Orders;