import request from './requestWrapper';

// Types
import {SessionType} from "../types/sessionTypes";


function login(data: SessionType) {
  return request({
    url: 'auth/login',
    method: 'POST',
    data,
  });
}

function register(data: SessionType) {
  return request({
    url: 'auth/register',
    method: 'POST',
    data,
  });
}

function confirmEmail(code: string) {
  return request({
    url: 'auth/confirmEmail',
    method: 'POST',
    params: { code },
  });
}

function forgotPassword(data: SessionType) {
  return request({
    url: 'auth/forgotPassword',
    method: 'POST',
    data,
  });
}

function resetPassword(data: SessionType) {
  return request({
    url: 'auth/resetPassword',
    method: 'POST',
    data,
  });
}

export default {
  login,
  register,
  confirmEmail,
  forgotPassword,
  resetPassword,
}