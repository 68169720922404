// Libraries
import React from 'react';
import {Col, Descriptions, Tag, Tooltip} from 'antd';
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {DownloadOutlined} from "@ant-design/icons";

// Utils
import {DATETIME_FORMAT} from "../../../utils/constants";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";
import {useAppDispatch} from "../../../store/store";
import {downloadFile} from "../../../store/redux/actions/transportActions";

// Components
import InputComponent from "../../subComponents/InputComponent";


const SpecificInformation = () => {
  const { stores } = useSelector(transportSelectors.transportParamsSelector);
  const detail = useSelector(transportSelectors.transportDetailSelector);
  const dispatch = useAppDispatch();

  return (
    <>
      <Descriptions bordered
                    column={2}
                    size={'small'}>
        <Descriptions.Item label={"Consecutivo"}>{detail.transportDetail?.serviceNumber}</Descriptions.Item>
        <Descriptions.Item label={"Hora anuncio de cargue"}>
          {dayjs(detail.transportDetail?.vehicleAnnouncementTime).format(DATETIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={"Hora inicio de cargue"}>
          {dayjs(detail.transportDetail?.loadingStartTime).format(DATETIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={"Hora fin de cargue"}>
          {dayjs(detail.transportDetail?.loadingEndTime).format(DATETIME_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label={"Número de expedición"}>{detail.transportDetail?.dispatchNumber}</Descriptions.Item>
        <Descriptions.Item label={"Número de UAT"}>{detail.transportDetail?.uatNumber}</Descriptions.Item>
        <Descriptions.Item label={"Número de cajas"}>{detail.transportDetail?.numberOfBoxes}</Descriptions.Item>
        <Descriptions.Item label={"Número de pallets"}>{detail.transportDetail?.numberOfPallets}</Descriptions.Item>
        <Descriptions.Item label={"Cajas a granel"}>{detail.transportDetail?.bulkBoxes}</Descriptions.Item>
        <Descriptions.Item label={"Cantidades enviadas"}>{detail.transportDetail?.sentQuantities}</Descriptions.Item>
        <Descriptions.Item label={"Intertiendas"}>{detail.transportDetail?.interstoreShipments}</Descriptions.Item>
        <Descriptions.Item label={"Dekapro"}>{detail.transportDetail?.dekaproShipments}</Descriptions.Item>
        <Descriptions.Item label={"C&C"}>{detail.transportDetail?.ccShipments}</Descriptions.Item>
        <Descriptions.Item label={"Envíos sin flujos"}>{detail.transportDetail?.shipmentsWithoutFlows}</Descriptions.Item>
        <Descriptions.Item label={"Reclamaciones"}>{detail.transportDetail?.claims}</Descriptions.Item>
        <Descriptions.Item label={"Publicidad"}>{detail.transportDetail?.advertising}</Descriptions.Item>
        <Descriptions.Item label={"Agenciamiento"}>{detail.transportDetail?.agencyFees}</Descriptions.Item>
        <Descriptions.Item label={"Canastas"}>{detail.transportDetail?.baskets}</Descriptions.Item>
        <Descriptions.Item label={"Dollies"}>{detail.transportDetail?.dollies}</Descriptions.Item>
        <Descriptions.Item label={"Tapas"}>{detail.transportDetail?.lids}</Descriptions.Item>
        <Descriptions.Item span={2} label={"Magnus"}>{detail.transportDetail?.magnusShipments}</Descriptions.Item>
        <Descriptions.Item span={2} label={"Números de precinto"}>
          {detail.destinations?.map((destination, index) =>
            <Tag key={index}>
              {`${stores.find((store) => store.id === destination.destinationStoreId)?.value}: 
                ${detail.transportDetail?.sealNumbers[index] ?? '-'}`
              }
            </Tag>
          )}
        </Descriptions.Item>
      </Descriptions>

      {detail.transportDetail!.files!.length > 0 &&
        <Col className={'grid grid-cols-2 gap-x-4 gap-y-4 mt-5'}>
          <span className={'col-span-2 ant-descriptions-title'}>Archivos</span>
          {detail.transportDetail?.files?.map((file) =>
            <Col key={file.url} className={'flex gap-x-3'}>
              <InputComponent value={file.url}
                              title={file.url}
                              disabled={true}
                              readOnly={true}
                              className={'bg-white text-grey-dkt-600'} />
              <Tooltip title={'Descargar archivo'}>
                <DownloadOutlined className={'text-lg m-auto cursor-pointer hover:text-red-dkt-400'}
                                  onClick={() => dispatch(downloadFile(file.fileId!))} />
              </Tooltip>
            </Col>
          )}
        </Col>
      }
    </>
  );
}

export default SpecificInformation;
