// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import locationServices from '../../../api/locationServices';

// Types
import {locationTypes} from '../types';
import {LocationPaginationType, LocationType} from "../../../types/locationTypes";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getAll = (data: LocationPaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  locationServices.getAll(data)
    .then((response) => {
      dispatch({
        type: locationTypes.GET_ALL_LOCATIONS,
        locationsResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (location: LocationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  locationServices.create(location)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ paginationVm: { page: 1, size: 10}, filterLocationVm: {} }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (location: LocationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  locationServices.update(location)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ paginationVm: { page: 1, size: 10}, filterLocationVm: {} }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}