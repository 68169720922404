import {ReactNode} from "react";
import {Col, message} from "antd";

// Types
import { RemissionType } from "../types/remissionTypes";
import { ThresholdType } from "../types/thresholdTypes";
import { InboundType } from "../types/inboundTypes";
import { TariffType } from "../types/tariffTypes";
import { CtnType } from "../types/ctnTypes";
import { FilterParametersType } from "../types/sharedTypes";
import {AllianceType} from "../types/allianceTypes";
import {StoreType} from "../types/storeTypes";
import {PackageSizeType} from "../types/packageSizeTypes";
import {OrderType} from "../types/orderTypes";
import {LocationType} from "../types/locationTypes";
import {SupplierType} from "../types/supplierTypes";
import {CarrierType} from "../types/carrierTypes";
import {CarrierUserType} from "../types/carrierUserTypes";
import {StoreMapType} from "../types/storeMapTypes";
import {UserStoreMapType} from "../types/userStoreMapTypes";
import {TransportType} from "../types/transportTypes";

// Functions and Constants
import { getLocalStorageItem, getMonths } from "./functions";
import { ROLES } from "./constants";


export const uploadProps = (
  acceptFormat: string,
  callback: (file: File) => void
) => {
  return {
    multiple: false,
    showUploadList: false,
    accept: acceptFormat,
    customRequest({ file, onSuccess, onError }: any) {
      let error = false;

      if (file.type === "" || !acceptFormat.includes(file.type)) {
        message.error({
          content: `El archivo "${file.name}" no está permitido.`,
        });
        error = true;
      } else if (file.size / 1024 / 1024 > 14) {
        message.error({
          content: `El archivo "${file.name}" sobrepasa el tamaño permitido (14MB).`,
        });
        error = true;
      }

      if (error) {
        setTimeout(() => {
          onError("error");
        }, 0);
        return;
      }

      setTimeout(() => {
        onSuccess("ok");
      }, 0);
      callback(file);
    },
  };
};

export const getTableColumns = (
  module: string,
  actionButtons: (id: string, infoExtra?: any) => ReactNode
) => {
  const renderRole = getLocalStorageItem("renderRole");
  const isAdminFreight = renderRole === ROLES.freightAdmin.name;
  let columns: any = [];

  if (module === "ctn") {
    columns = [
      {
        title: "Número contenedor",
        dataIndex: "containerNumber",
        key: "containerNumber",
        width: "150px",
      },
      {
        title: "Embarcación",
        dataIndex: "vesselName",
        key: "vesselName",
        width: "200px",
        ellipsis: true,
      },
      { title: "Pasaje", dataIndex: "voyage", key: "voyage", width: "100px" },
      {
        title: "Puerto carga",
        dataIndex: "loadingPort",
        key: "loadingPort",
        width: "150px",
      },
      {
        title: "Puerto descarga",
        dataIndex: "dischargePort",
        key: "dischargePort",
        width: "150px",
      },
      {
        title: "Tamaño contenedor",
        dataIndex: "containerSize",
        key: "containerSize",
        width: "150px",
      },
      {
        title: "Número pedido",
        dataIndex: "orderNb",
        key: "orderNb",
        width: "150px",
      },
      {
        title: "Código modelo",
        dataIndex: "modelCode",
        key: "modelCode",
        width: "150px",
      },
      {
        title: "Código artículo",
        dataIndex: "itemCode",
        key: "itemCode",
        width: "150px",
      },
      {
        title: "Tamaño artículo",
        dataIndex: "itemSize",
        key: "itemSize",
        width: "150px",
      },
      {
        title: "Cantidad CTN",
        dataIndex: "ctnQuantity",
        key: "ctnQuantity",
        width: "100px",
      },
      {
        title: "Cantidad artículo",
        dataIndex: "itemQuantity",
        key: "itemQuantity",
        width: "150px",
      },
      { title: "GW", dataIndex: "gw", key: "gw", width: "100px" },
      { title: "NW", dataIndex: "nw", key: "nw", width: "100px" },
      { title: "Volumen", dataIndex: "volume", key: "volume", width: "100px" },
      { title: "PCB", dataIndex: "pcb", key: "pcb", width: "100px" },
      { title: "DPP", dataIndex: "dpp", key: "dpp", width: "100px" },
      {
        title: "Promotor",
        dataIndex: "forwarder",
        key: "forwarder",
        width: "150px",
        ellipsis: true,
      },
      { title: "Blnb", dataIndex: "blnb", key: "blnb", width: "150px" },
      {
        title: "Remisión",
        dataIndex: "remission",
        key: "remission",
        width: "200px",
      },
      {
        title: "Tipo contenedor origen",
        dataIndex: "originContainerType",
        key: "originContainerType",
        width: "200px",
        ellipsis: true,
      },
      {
        title: "Año fiscal",
        dataIndex: "fiscalYear",
        key: "fiscalYear",
        width: "150px",
      },
      {
        title: "Valor flete",
        dataIndex: "freightValueTender",
        key: "freightValueTender",
        width: "150px",
      },
      {
        title: "Peso flete",
        dataIndex: "freightValueWeight",
        key: "freightValueWeight",
        width: "150px",
        render: (value: number) => Number(value).toFixed(2),
      },
      {
        title: "PCS flete",
        dataIndex: "freightValuePcs",
        key: "freightValuePcs",
        width: "100px",
        render: (value: number) => Number(value).toFixed(4),
      },
    ];
    isAdminFreight &&
      columns.push({
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "80px",
        fixed: "right",
        render: (text: any, record: CtnType) => <Col className={'text-center'}>{actionButtons(record.ctnId)}</ Col>,
      });
  } else if (module === "tariff") {
    columns = [
      { title: "Año", dataIndex: "year", key: "year", width: "100px" },
      { title: "Código", dataIndex: "code", key: "code", width: "150px" },
      { title: "Origen", dataIndex: "origin", key: "origin", width: "150px" },
      { title: "Tipo", dataIndex: "type", key: "type", width: "100px" },
      {
        title: "Compañía naviera",
        dataIndex: "shippingCompany",
        key: "shippingCompany",
        width: "150px",
      },
      {
        title: "Número contenedor",
        dataIndex: "containerNumber",
        key: "containerNumber",
        width: "150px",
        render: (value: any) => value ?? "-",
      },
      { title: "Flete", dataIndex: "freight", key: "freight", width: "100px" },
    ];
    isAdminFreight &&
      columns.push({
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "80px",
        fixed: "right",
        render: (text: any, record: TariffType) =>
          <Col className={'text-center'}>{actionButtons(record.tariffId)}</ Col>,
      });
  } else if (module === "threshold") {
    columns = [
      {
        title: "Referencia",
        dataIndex: "reference",
        key: "reference",
        width: "100px",
      },
      {
        title: "Subpartida",
        dataIndex: "subDeparture",
        key: "subDeparture",
        width: "100px",
      },
      { title: "Tipo", dataIndex: "type", key: "type", width: "100px" },
      {
        title: "Umbral",
        dataIndex: "threshold",
        key: "threshold",
        width: "100px",
      },
    ];
    isAdminFreight &&
      columns.push({
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "50px",
        render: (text: any, record: ThresholdType) =>
          <Col className={'text-center'}>{actionButtons(record.thresholdId)}</ Col>,
      });
  } else if (module === "remission") {
    columns = [
      {
        title: "N° de envío",
        dataIndex: "shippingNumber",
        key: "shippingNumber",
        width: "100px",
      },
      {
        title: "N° contenedor",
        dataIndex: "containerNumber",
        key: "containerNumber",
        width: "150px",
      },
      { title: "PO", dataIndex: "po", key: "po", width: "100px" },
      { title: "BL", dataIndex: "bl", key: "bl", width: "150px" },
      { title: "NS", dataIndex: "ns", key: "ns", width: "100px" },
      {
        title: "Manifiesto",
        dataIndex: "manifest",
        key: "manifest",
        width: "150px",
      },
      {
        title: "Código del modelo",
        dataIndex: "modelCode",
        key: "modelCode",
        width: "150px",
      },
      {
        title: "Código del artículo",
        dataIndex: "itemCode",
        key: "itemCode",
        width: "150px",
      },
      {
        title: "País de origen",
        dataIndex: "originCountry",
        key: "originCountry",
        width: "150px",
      },
      {
        title: "Cantidad",
        dataIndex: "quantity",
        key: "quantity",
        width: "100px",
      },
      {
        title: "Datos",
        dataIndex: "data",
        key: "data",
        width: "350px",
        ellipsis: true,
      },
      {
        title: "Precio",
        dataIndex: "price",
        key: "price",
        width: "150px",
        render: (value: number) => Number(value).toFixed(2),
      },
      {
        title: "Valor total",
        dataIndex: "totalValue",
        key: "totalValue",
        width: "150px",
        render: (value: number) => Number(value).toFixed(2),
      },
      {
        title: "Peso neto",
        dataIndex: "netWeight",
        key: "netWeight",
        width: "150px",
        render: (value: number) => Number(value).toFixed(2),
      },
      {
        title: "Peso bruto",
        dataIndex: "grossWeight",
        key: "grossWeight",
        width: "150px",
        render: (value: number) => Number(value).toFixed(2),
      },
      { title: "#BL", dataIndex: "numberBl", key: "numberBl", width: "50px" },
      {
        title: "Remisión",
        dataIndex: "remission",
        key: "remission",
        width: "200px",
      },
      {
        title: "Puerto de origen",
        dataIndex: "origin",
        key: "origin",
        width: "150px",
      },
      {
        title: "Año fiscal",
        dataIndex: "fiscalYear",
        key: "fiscalYear",
        width: "100px",
      },
      {
        title: "Precio del flete pcs",
        dataIndex: "freightValuePcs",
        key: "freightValuePcs",
        width: "200px",
        render: (value: number) => Number(value).toFixed(4),
      },
      {
        title: "FOB",
        dataIndex: "fob",
        key: "fob",
        width: "100px",
        render: (value: number) => Number(value).toFixed(4),
      },
      {
        title: "Valor del umbral",
        dataIndex: "thresholdValue",
        key: "thresholdValue",
        width: "150px",
      },
      {
        title: "Estado del umbral",
        dataIndex: "thresholdStatus",
        key: "thresholdStatus",
        width: "150px",
      },
    ];
    isAdminFreight &&
      columns.push({
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "80px",
        fixed: "right",
        render: (text: any, record: RemissionType) =>
          <Col className={'text-center'}>{actionButtons(record.remissionId)}</ Col>,
      });
  } else if (module === "inbound") {
    columns = [
      {
        title: "N° contenedor",
        dataIndex: "containerNumber",
        key: "containerNumber",
        width: "100px",
      },
      {
        title: "Tamaño contenedor",
        dataIndex: "containerSize",
        key: "containerSize",
        width: "100px",
      },
      {
        title: "Semana ETA",
        dataIndex: "etaWeek",
        key: "etaWeek",
        width: "100px",
      },
      {
        title: "Mes",
        dataIndex: "month",
        key: "month",
        width: "100px",
        render: (value: number) =>
          getMonths().find((month) => month.id == value)!.value,
      },
      {
        title: "Fecha ETA",
        dataIndex: "etaDate",
        key: "etaDate",
        width: "100px",
      },
      {
        title: "Fecha ATA",
        dataIndex: "ataDate",
        key: "ataDate",
        width: "100px",
      },
      { title: "BL", dataIndex: "bl", key: "bl", width: "150px" },
      {
        title: "Línea de compra",
        dataIndex: "shippingLine",
        key: "shippingLine",
        width: "100px",
      },
      { title: "Tipo", dataIndex: "type", key: "type", width: "100px" },
    ];
    isAdminFreight &&
      columns.push({
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "50px",
        fixed: "right",
        render: (text: any, record: InboundType) =>
          <Col className={'text-center'}>{actionButtons(record.inboundId)}</ Col>,
      });
  } else if (module === "alliances") {
    columns = [
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        width: "100px",
      },
      {
        title: "Tipo",
        dataIndex: "type",
        key: "type",
        width: "100px",
      },
      {
        title: "Color",
        dataIndex: "color",
        key: "color",
        width: "100px",
      },
      {
        title: "Activa",
        dataIndex: "isActive",
        key: "isActive",
        width: "50px",
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "50px",
        fixed: "right",
        render: (_: any, alliance: AllianceType) => <Col className={'text-center'}>{actionButtons(alliance.allianceId)}</ Col>,
      }
    ];
  } else if (module === "stores") {
    columns = [
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        width: "100px",
      },
      {
        title: "Código",
        dataIndex: "code",
        key: "code",
        width: "100px",
      },
      {
        title: "Nombre largo",
        dataIndex: "longName",
        key: "longName",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Dirección",
        dataIndex: "address",
        key: "address",
        width: "100px",
      },
      {
        title: "Teléfono",
        dataIndex: "phone",
        key: "phone",
        width: "100px",
      },
      {
        title: "Código postal",
        dataIndex: "zipCode",
        key: "zipCode",
        width: "100px",
      },
      {
        title: "Activa",
        dataIndex: "isActive",
        key: "isActive",
        width: "50px",
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "80px",
        fixed: "right",
        render: (_: any, store: StoreType) => <Col className={'text-center'}>{actionButtons(store.storeId)}</ Col>,
      }
    ];
  } else if (module === "packageSizes") {
    columns = [
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        width: "50px",
      },
      {
        title: "Alto (cm)",
        dataIndex: "height",
        key: "height",
        width: "50px",
      },
      {
        title: "Ancho (cm)",
        dataIndex: "width",
        key: "width",
        width: "50px",
      },
      {
        title: "Profundo (cm)",
        dataIndex: "depth",
        key: "depth",
        width: "50px",
      },
      {
        title: "Peso (kg)",
        dataIndex: "weight",
        key: "weight",
        width: "50px",
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "50px",
        fixed: "right",
        render: (_: any, packageSize: PackageSizeType) => <Col className={'text-center'}>{actionButtons(packageSize.packageSizeId)}</ Col>,
      }
    ];
  } else if (module === "orders") {
    columns = [
      {
        title: "Número",
        dataIndex: "orderNumber",
        key: "orderNumber",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Contacto",
        dataIndex: "contact",
        key: "contact",
        width: "200px",
        ellipsis: true
      },
      {
        title: "Documento",
        dataIndex: "contactDocument",
        key: "contactDocument",
        width: "150px",
        ellipsis: true
      },
      {
        title: "Código de ciudad",
        dataIndex: "cityCode",
        key: "cityCode",
        width: "150px",
        ellipsis: true
      },
      {
        title: "Dirección",
        dataIndex: "clientAddress",
        key: "clientAddress",
        width: "150px",
        ellipsis: true
      },
      {
        title: "Detalles",
        dataIndex: "details",
        key: "details",
        width: "200px",
        ellipsis: true
      },
      {
        title: "Teléfono",
        dataIndex: "phone",
        key: "phone",
        width: "150px",
        ellipsis: true
      },
      {
        title: "Correo",
        dataIndex: "email",
        key: "email",
        width: "150px",
        ellipsis: true
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "80px",
        fixed: "right",
        render: (_: any, order: OrderType) => <Col className={'text-center'}>{actionButtons(order.orderNumber)}</ Col>,
      }
    ];
  } else if (module === "locations") {
    columns = [
      {
        title: "Código Postal",
        dataIndex: "zipCode",
        key: "zipCode",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Código Dane",
        dataIndex: "daneCode",
        key: "daneCode",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Código Coordinadora",
        dataIndex: "coordinadoraCode",
        key: "coordinadoraCode",
        width: "100px",
        ellipsis: true
      },
      {
        title: <Col className={'text-center'}>¿Activa?</Col>,
        dataIndex: "isActive",
        key: "isActive",
        width: "80px",
        ellipsis: true,
        render: (isActive: boolean) => <Col className={'text-center'}>{isActive ? 'SI' : 'NO'}</Col>
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "80px",
        fixed: "right",
        render: (_: any, location: LocationType) => <Col className={'text-center'}>{actionButtons(location.locationId)}</ Col>,
      }
    ];
  } else if (module === "suppliers") {
    columns = [
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        width: "100px",
      },
      {
        title: "Correo",
        dataIndex: "email",
        key: "email",
        width: "100px",
        ellipsis: true
      },
      {
        title: "¿Confirmado?",
        dataIndex: "confirmed",
        key: "confirmed",
        width: "50px",
      },
      {
        title: "¿Activo?",
        dataIndex: "isActive",
        key: "isActive",
        width: "50px",
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "30px",
        fixed: "right",
        render: (_: any, supplier: SupplierType) => <Col className={'text-center'}>{actionButtons(supplier.supplierUserId ?? '')}</ Col>,
      }
    ];
  } else if (module === "carriers") {
    columns = [
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        width: "100px",
        ellipsis: true
      },
      {
        title: "NIT",
        dataIndex: "nit",
        key: "nit",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Dirección",
        dataIndex: "address",
        key: "address",
        width: "100px",
        ellipsis: true
      },
      {
        title: <Col className={'text-center'}>¿Activa?</Col>,
        dataIndex: "isActive",
        key: "isActive",
        width: "50px",
        ellipsis: true,
        render: (isActive: boolean) => <Col className={'text-center'}>{isActive ? 'SI' : 'NO'}</Col>
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "50px",
        fixed: "right",
        render: (_: any, carrier: CarrierType) => <Col className={'text-center'}>{actionButtons(carrier.carrierId!)}</ Col>,
      }
    ];
  } else if (module === "carrierUsers") {
    columns = [
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Correo",
        dataIndex: "email",
        key: "email",
        width: "100px",
        ellipsis: true
      },
      {
        title: "¿Confirmado?",
        dataIndex: "confirmed",
        key: "confirmed",
        width: "50px"
      },
      {
        title: "Transportista",
        dataIndex: "carrierName",
        key: "carrierName",
        width: "100px",
        ellipsis: true
      },
      {
        title: <Col className={'text-center'}>¿Activo?</Col>,
        dataIndex: "isActive",
        key: "isActive",
        width: "50px",
        ellipsis: true,
        render: (isActive: boolean) => <Col className={'text-center'}>{isActive ? 'SI' : 'NO'}</Col>
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "50px",
        fixed: "right",
        render: (_: any, carrierUser: CarrierUserType) => <Col className={'text-center'}>{actionButtons(carrierUser.carrierUserId!)}</ Col>,
      }
    ];
  } else if (module === "storeMaps") {
    columns = [
      {
        title: "Nombre",
        dataIndex: "name",
        key: "name",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Código",
        dataIndex: "code",
        key: "code",
        width: "50px",
        ellipsis: true
      },
      {
        title: "Dirección",
        dataIndex: "address",
        key: "address",
        width: "200px",
        ellipsis: true,
      },
      {
        title: "Prefijo",
        dataIndex: "prefix",
        key: "prefix",
        width: "100px",
        ellipsis: true,
        render: (prefix: string) => prefix ?? '-'
      },
      {
        title: <Col className={'text-center'}>Principal</Col>,
        dataIndex: "isMain",
        key: "isMain",
        width: "50px",
        ellipsis: true,
        render: (isActive: boolean) => <Col className={'text-center'}>{isActive ? 'SI' : 'NO'}</Col>
      },
      {
        title: <Col className={'text-center'}>Activa</Col>,
        dataIndex: "isActive",
        key: "isActive",
        width: "50px",
        ellipsis: true,
        render: (isActive: boolean) => <Col className={'text-center'}>{isActive ? 'SI' : 'NO'}</Col>
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "50px",
        fixed: "right",
        render: (_: any, storeMap: StoreMapType) => <Col className={'text-center'}>{actionButtons(storeMap.storeMapId!)}</ Col>,
      }
    ];
  } else if (module === "userStoreMaps") {
    columns = [
      {
        title: "Correo",
        dataIndex: "email",
        key: "email",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Tienda",
        dataIndex: "storeName",
        key: "storeName",
        width: "100px",
        ellipsis: true
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "50px",
        fixed: "right",
        render: (_: any, userStoreMap: UserStoreMapType) => <Col className={'text-center'}>{actionButtons(userStoreMap.userStoreMapId!)}</ Col>,
      }
    ];
  } else if (module === "transports") {
    columns = [
      {
        title: "ID",
        dataIndex: "transportEngineId",
        key: "transportEngineId",
        width: "120px",
        ellipsis: true,
        render: (id: string) => <span>{id.substring(id.length - 12).toUpperCase()}</span>
      },
      {
        title: "Transportista",
        dataIndex: "carrier",
        key: "carrier",
        width: "150px",
        ellipsis: true
      },
      {
        title: "Fecha",
        dataIndex: "startDatetime",
        key: "startDatetime",
        width: "150px",
        ellipsis: true
      },
      {
        title: "Estado",
        dataIndex: "state",
        key: "state",
        width: "100px",
        ellipsis: true
      },
      {
        title: "Restricciones",
        dataIndex: "restrictions",
        key: "restrictions",
        width: "200px",
        ellipsis: true
      },
      {
        title: "Destinos",
        dataIndex: "destinationIds",
        key: "destinationIds",
        width: "200px",
        ellipsis: true,
        render: (names: string[]) => <span>{names.join(' - ')}</span>
      },
      {
        title: <Col className={'text-center'}>Acciones</Col>,
        dataIndex: "actions",
        key: "actions",
        width: "80px",
        fixed: "right",
        render: (_: any, transport: TransportType) => <Col className={'text-center'}>
          {actionButtons(transport.transportEngineId!, transport.state)}
        </ Col>,
      }
    ];
  } else if (module === "returns") {
    columns = [
      {
        title: "Tienda",
        dataIndex: "storeName",
        key: "storeName",
        width: "100px",
        ellipsis: true,
      },
      {
        title: "Número de pallets",
        dataIndex: "pallets",
        key: "pallets",
        width: "150px",
      },
      {
        title: "Dollies",
        dataIndex: "dollies",
        key: "dollies",
        width: "100px",
      },
      {
        title: "Tapas",
        dataIndex: "lids",
        key: "lids",
        width: "100px",
      },
      {
        title: "Canastas",
        dataIndex: "baskets",
        key: "baskets",
        width: "100px",
      },
      {
        title: "Magnus",
        dataIndex: "magnus",
        key: "magnus",
        width: "100px",
      },
      {
        title: "Intertiendas",
        dataIndex: "interstoreShipments",
        key: "interstoreShipments",
        width: "100px",
      },
      {
        title: "Retornos CAR",
        dataIndex: "carReturns",
        key: "carReturns",
        width: "120px",
      },
      {
        title: "Reclamaciones",
        dataIndex: "claims",
        key: "claims",
        width: "120px",
      },
      {
        title: "Publicidad",
        dataIndex: "advertising",
        key: "advertising",
        width: "100px",
      },
    ];
  } else {
    columns = [];
  }

  return columns;
};

export const getFilterParameters = (module: string) => {
  let parameters: FilterParametersType[] = [];

  if (module === "ctn") {
    parameters = [
      { fieldName: "containerNumber", placeHolder: "Número de contenedor" },
      { fieldName: "itemCode", placeHolder: "Código del artículo" },
    ];
  } else if (module === "tariff") {
    parameters = [
      { fieldName: "text", placeHolder: "Código o número de contenedor" },
    ];
  } else if (module === "threshold") {
    parameters = [{ fieldName: "text", placeHolder: "Referencia" }];
  } else if (module === "remission") {
    parameters = [
      { fieldName: "shippingNumber", placeHolder: "Número del envío" },
      { fieldName: "containerNumber", placeHolder: "Número de contenedor" },
      { fieldName: "itemCode", placeHolder: "Código del artículo" },
    ];
  } else if (module === "inbound") {
    parameters = [{ fieldName: "text", placeHolder: "Número de contenedor" }];
  } else if (module === "order") {
    parameters = [
      { fieldName: "orderNumber", placeHolder: "Número de orden" },
      { fieldName: "dates", placeHolder: ['Fecha inicial', 'Fecha final'], type: 'rangeDate' },
    ];
  } else if (module === "location") {
    parameters = [
      { fieldName: "zipCode", placeHolder: "Código Postal" },
      { fieldName: "daneCode", placeHolder: "Código Dane" },
    ];
  } else if (module === "transport") {
    parameters = [
      { fieldName: "textFilter", placeHolder: 'Id del servicio' },
      { fieldName: "stateFilter", placeHolder: 'Estado', type: 'select' },
      { fieldName: "dates", placeHolder: ['Fecha inicial', 'Fecha final'], type: 'rangeDate' },
    ];
  }

  return parameters;
};
