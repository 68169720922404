import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Col, Space} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

// Store
import {useAppDispatch} from '../../store/store';
import * as orderSelectors from '../../store/redux/selectors/orderSelectors';
import {getAll} from '../../store/redux/actions/orderActions';

// Types
import {OrderPaginationType, OrderType} from "../../types/orderTypes";

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';
import FiltersComponent from "../subComponents/FiltersComponent";

// Functions and Constants
import {getFilterParameters, getTableColumns} from '../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, orderNumber: string) => void;
}
const OrdersTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [orderList, setOrderList] = useState<OrderType[]>([]);
  const [paginationData, setPaginationData] = useState<OrderPaginationType>({
    paginationVm: { page: 1, size: 10 },
    filterVm: {
      orderNumber: undefined, status: undefined, startDate: undefined, endDate: undefined
    }
  });
  const { rows, count, size, page } = useSelector(orderSelectors.allOrdersSelector);
  const filterParameters = getFilterParameters('order');

  useEffect(() => {
    const newPaginationData = paginationData;

    if (paginationData.filterVm.dates) {
      newPaginationData.filterVm.startDate = dayjs(paginationData.filterVm.dates[0]).format('YYYY-MM-DDT00:00:00');
      newPaginationData.filterVm.endDate = dayjs(paginationData.filterVm.dates[1]).format('YYYY-MM-DDT23:59:59');
    }
    dispatch(getAll(newPaginationData));
  }, [paginationData]);

  useEffect(() => {
    const data: any[] = [];

    rows.map(order => {
      data.push({
        orderNumber: order.orderNumber,
        contact: order.contact,
        contactDocument: order.contactDocument,
        cityCode: order.cityCode,
        clientAddress: order.clientAddress,
        details: order.details,
        phone: order.phone,
        email: order.email,
      });
    });

    setOrderList(data);
  }, [rows]);

  const handleFormModal = (visible: boolean, orderNumber: string) => {
    onToggleModal(visible, orderNumber);
  }

  const actionButtons = (orderNumber: string) => {
    return (
      <Space>
        <EyeOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                     title={'Ver detalle'}
                     onClick={() => handleFormModal(true, orderNumber)} />
      </Space>
    )
  };

  return (
    <>
      <Col md={24} xs={24} className={'mt-5'}>
        <FiltersComponent filterParameters={filterParameters}
                          onChangeFilters={(values) => setPaginationData({ paginationVm: { page, size }, filterVm: values })} />
      </Col>

      <TableComponent pageSize={size}
                      currentPage={page}
                      total={count}
                      dataSource={orderList}
                      scrollX={1300}
                      rowKey={'orderNumber'}
                      columns={getTableColumns('orders', actionButtons)}
                      onChangePage={(pagination) => setPaginationData({ paginationVm: pagination, filterVm: paginationData.filterVm })} />
    </>
  );
}

export default OrdersTable;