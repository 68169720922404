import request from './requestWrapper';

// Types
import {OrderPaginationType} from "../types/orderTypes";


function getDetailByOrderNumber(orderNumber: string) {
  return request({
    url: `order/getDetailByOrderNumber/${orderNumber}`,
    method: 'Get',
  });
}
function getAll(data: OrderPaginationType) {
  return request({
    url: 'order/getAll',
    method: 'POST',
    data: data
  });
}

export default {
  getAll,
  getDetailByOrderNumber,
}