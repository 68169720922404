import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {CarrierType} from "../types/carrierTypes";


function getAll(pagination: PaginationType) {
  return request({
    url: 'carrier/getAll',
    method: 'GET',
    params: pagination
  });
}
function create(carrier: CarrierType) {
  return request({
    url: 'carrier/create',
    method: 'POST',
    data: carrier,
  });
}
function update(carrier: CarrierType, carrierId: string) {
  return request({
    url: `carrier/update/${carrierId}`,
    method: 'PUT',
    data: carrier,
  });
}

export default {
  getAll,
  create,
  update,
};
