// Libraries
import {configureStore} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';

// Reducers combiner
import reducer from './redux/reducers';

export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;