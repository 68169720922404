// Libraries
import React, {useEffect, useState} from 'react';
import {Row, Modal, Descriptions, Space} from 'antd';
import {useAppDispatch} from '../../store/store';
import {useSelector} from "react-redux";
import {TagsOutlined} from "@ant-design/icons";

// Store
import { getDetailByOrderNumber } from '../../store/redux/actions/orderActions';
import * as orderSelectors from "../../store/redux/selectors/orderSelectors";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import TableComponent from "../subComponents/TableComponent";

// Utils
import {tableColumns} from "./tableColumns";

// Types
import {PaginationType} from "../../types/sharedTypes";
import {PackageItemType} from "../../types/shipmentTypes";
import ItemsDrawer from "./ItemsDrawer";


type PropsType = {
  orderNumber: string;
  onToggleModal: (visible: boolean, orderNumber: string) => void;
};
const OrdersFormModal = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { orderNumber, onToggleModal } = props;
  const detail = useSelector(orderSelectors.orderDetailSelector);
  const [showDrawer, setShowDrawer] = useState(false);
  const [packageItems, setPackageItems] = useState<PackageItemType[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationType>(
    { page: 1, size: 10 }
  );

  useEffect(() => {
    dispatch(getDetailByOrderNumber(orderNumber));
  }, []);

  const handleDrawer = (visible: boolean, packageItems: PackageItemType[]) => {
    setShowDrawer(visible);
    setPackageItems(packageItems);
  }

  const actionButtons = (packageItems: PackageItemType[]) => {
    return (
      <Space>
        {packageItems.length > 0
          && <TagsOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                           title={'Ver artículos del paquete'}
                           onClick={() => handleDrawer(true, packageItems)} />
        }
      </Space>
    )
  };

  return (
    <Modal title={'Detalle de la orden'}
           width={1000}
           open={true} maskClosable={true} closable={true} keyboard={false} destroyOnClose={true}
           onCancel={() => onToggleModal(false, '')}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, '')} />,
           ]}>
      <Row>
        <Descriptions className={'w-full [&>div>table>tbody>tr>th]:font-bold last:[&>div>table>tbody>tr]:hidden'}
                      layout={'vertical'}
                      bordered
                      size={'small'}
        >
          <Descriptions.Item label={"Número de orden"}>{detail.orderNumber}</Descriptions.Item>
          <Descriptions.Item label={"Contacto"}>{detail.contact}</Descriptions.Item>
          <Descriptions.Item label={"Documento del contacto"}>{detail.contactDocument}</Descriptions.Item>
          <Descriptions.Item label={"Código de ciudad"}>{detail.cityCode}</Descriptions.Item>
          <Descriptions.Item label={"Dirección"}>{detail.clientAddress}</Descriptions.Item>
          <Descriptions.Item label={"Detalles"}>{detail.details}</Descriptions.Item>
          <Descriptions.Item label={"Teléfono"}>{detail.phone}</Descriptions.Item>
          <Descriptions.Item label={"Correo"} span={2}>{detail.email}</Descriptions.Item>
          <Descriptions.Item label={"Envíos"}>{}</Descriptions.Item>
        </Descriptions>

        <TableComponent className={''}
                        pageSize={paginationData.size}
                        currentPage={paginationData.page}
                        total={detail.shipments!.length}
                        dataSource={detail.shipments!}
                        scrollX={1600}
                        rowKey={'shipmentId'}
                        columns={tableColumns(actionButtons)}
                        onChangePage={setPaginationData} />
      </Row>

      {showDrawer &&
        <ItemsDrawer packageItems={packageItems}
                     onToggleDrawer={handleDrawer} />
      }
    </Modal>
  );
}

export default OrdersFormModal;