import React, {useEffect, useState} from 'react';
import {Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../../store/store';
import * as storeMapSelectors from '../../../store/redux/selectors/storeMapSelectors';
import {getAll} from '../../../store/redux/actions/storeMapActions';

// Types
import {StoreMapType} from '../../../types/storeMapTypes';
import {PaginationType} from "../../../types/sharedTypes";

// Components and Subcomponents
import TableComponent from '../../subComponents/TableComponent';

// Functions and Constants
import {getTableColumns} from '../../../utils/componentsUtils';
import {allStoreMapsSelector} from "../../../store/redux/selectors/storeMapSelectors";


type PropsType = {
  onToggleModal: (visible: boolean, storeMapToEdit?: StoreMapType) => void;
}
const StoreMapsTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1, size: 10
  });
  const { rows, count, size, page } = useSelector(storeMapSelectors.allStoreMapsSelector);

  useEffect(() => {
    dispatch(getAll(paginationData));
  }, [paginationData]);

  const handleFormModal = (visible: boolean, storeMapId?: string) => {
    const storeMapToEdit = rows.find(storeMap => storeMap.storeMapId === storeMapId);

    onToggleModal(visible, storeMapToEdit);
  }

  const actionButtons = (storeMapId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, storeMapId)} />
      </Space>
    )
  };

  return (
    <>
      <TableComponent pageSize={size}
                      currentPage={page}
                      total={count}
                      dataSource={rows}
                      scrollX={1000}
                      rowKey={'storeMapId'}
                      columns={getTableColumns('storeMaps', actionButtons)}
                      onChangePage={(pagination) => {
                        setPaginationData({ page: pagination.page, size: pagination.size });
                      }} />
    </>
  );
}

export default StoreMapsTable;
