// Libraries
import React, {FunctionComponent} from 'react';
import {Table} from 'antd';

// Types
import { FilterType, PaginationType} from '../../types/sharedTypes';


type TableComponentType = {
  pageSize: number;
  currentPage: number;
  total: number;
  scrollX: number;
  filterText?: string;
  filters?: FilterType;
  rowKey: string;
  className?: string;
  dataSource: any[];
  columns: any[];
  onChangePage: (pagination: PaginationType) => void;
};
const TableComponent: FunctionComponent<TableComponentType> = (
  {
    pageSize, currentPage, total, scrollX, filterText, filters,
    rowKey, className, dataSource, columns, onChangePage
  }) => {
  return (
    <Table dataSource={dataSource} rowKey={rowKey} size={'small'}
           className={className}
           columns={columns}
           scroll={{ x: scrollX, y: 500 }}
           pagination={{
             size: 'small',
             pageSize: pageSize,
             current: currentPage,
             showSizeChanger: true,
             total: total,
             onChange: (page, pageSize) => onChangePage({ page: page, size: pageSize, text: filterText, filters: filters }),
           }} />
  );
}

export default TableComponent;