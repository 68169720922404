import request from './requestWrapper';

// Types
import {LocationPaginationType, LocationType} from "../types/locationTypes";


function getAll(data: LocationPaginationType) {
  return request({
    url: 'location/getAll',
    method: 'POST',
    data: data
  });
}
function create(location: LocationType) {
  return request({
    url: 'location/create',
    method: 'POST',
    data: location
  });
}
function update(packageSize: LocationType) {
  return request({
    url: 'location/update',
    method: 'PUT',
    data: packageSize
  });
}

export default {
  getAll,
  create,
  update,
}