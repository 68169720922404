import React, {useEffect, useState} from 'react';
import {Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../../store/store';
import * as carrierSelectors from '../../../store/redux/selectors/carrierSelectors';
import {getAll} from '../../../store/redux/actions/carrierActions';

// Types
import {CarrierType} from '../../../types/carrierTypes';
import {PaginationType} from "../../../types/sharedTypes";

// Components and Subcomponents
import TableComponent from '../../subComponents/TableComponent';

// Functions and Constants
import {getTableColumns} from '../../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, carrierToEdit?: CarrierType) => void;
}
const CarriersTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1, size: 10
  });
  const { rows, count, size, page } = useSelector(carrierSelectors.allCarriersSelector);

  useEffect(() => {
    dispatch(getAll(paginationData));
  }, [paginationData]);

  const handleFormModal = (visible: boolean, carrierId?: string) => {
    const carrierToEdit = rows.find(carrier => carrier.carrierId === carrierId);

    onToggleModal(visible, carrierToEdit);
  }

  const actionButtons = (carrierId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, carrierId)} />
      </Space>
    )
  };

  return (
    <>
      <TableComponent pageSize={size}
                      currentPage={page}
                      total={count}
                      dataSource={rows}
                      scrollX={700}
                      rowKey={'carrierId'}
                      columns={getTableColumns('carriers', actionButtons)}
                      onChangePage={(pagination) => {
                        setPaginationData({ page: pagination.page, size: pagination.size });
                      }} />
    </>
  );
}

export default CarriersTable;
