// Libraries
import React, {FunctionComponent, ReactElement} from 'react';
import {Button} from 'antd';
import PropTypes from 'prop-types';

// Functions
import {isEmpty} from '../../utils/functions';


type ButtonComponentType = {
  htmlType?: 'submit' | 'reset' | 'button' | undefined;
  className?: string;
  text: string;
  loading?: boolean;
  disabled?: boolean;
  icon?: ReactElement | null;
  onClick?: () => void;
};
const ButtonComponent: FunctionComponent<ButtonComponentType> = (
  {
    htmlType, className, text, loading, disabled, icon, onClick,
  }) => {
  const textIsEmpty = isEmpty(text);

  return (
    <Button htmlType={htmlType} loading={loading} icon={icon} disabled={disabled}
            className={`${textIsEmpty ? !className?.includes('h-') && 'h-7 ' : 'h-fit '} ` +
              'rounded-md text-sm font-medium shadow-md ' + className
            }
            onClick={onClick}>
      {text}
    </Button>
  );
}

ButtonComponent.propTypes = {
  htmlType: PropTypes.oneOf(['submit', 'reset', 'button', undefined]),
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};
ButtonComponent.defaultProps = {
  htmlType: 'button',
  className: '',
  text: '',
  loading: false,
  disabled: false,
  icon: null,
  onClick: () => {
    return null;
  }
};

export default ButtonComponent;