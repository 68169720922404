// Libraries
import React from 'react';
import {Row, Form, Modal} from 'antd';
import {useAppDispatch} from '../../store/store';

// Store
import { create, update } from '../../store/redux/actions/packageSizeActions';

// Types
import {PackageSizeType} from "../../types/packageSizeTypes";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';
import {ERROR_MODAL} from "../subComponents/responseModals";


type PropsType = {
  isEditing?: boolean;
  packageSizeToEdit?: PackageSizeType;
  onToggleModal: (visible: boolean) => void;
};
const PackageSizesFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isEditing, packageSizeToEdit, onToggleModal } = props;

  const handleSubmit = (packageSize: PackageSizeType) => {
    const newHeight = parseFloat(packageSize.height.toString());
    const newWidth = parseFloat(packageSize.width.toString());
    const newDepth = parseFloat(packageSize.depth.toString());
    const newWeight = parseFloat(packageSize.weight.toString());
    if (isNaN(newHeight) || isNaN(newWidth) || isNaN(newDepth)|| isNaN(newWeight)) {
      ERROR_MODAL('Los valores de alto, ancho y profundo deben ser números positivos.');
      return;
    }

    if(isEditing){
      packageSize.packageSizeId = packageSizeToEdit!.packageSizeId;

      dispatch(update(packageSize));
    } else {
      dispatch(create(packageSize));
    }
    onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} dimensión`}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            initialValues={packageSizeToEdit ?? {}}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          <FormItemComponent name={'name'}
                             label={'Nombre'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'height'}
                             label={'Alto (cm)'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'width'}
                             label={'Ancho (cm)'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'depth'}
                             label={'Profundo'}
                             required={true}
                             child={<InputComponent />}
          />
          <FormItemComponent name={'weight'}
                             label={'Peso'}
                             required={true}
                             child={<InputComponent />}
          />
        </Row>
      </Form>
    </Modal>
  );
}

export default PackageSizesFormModal;