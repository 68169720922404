// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import storeServices from '../../../api/storeServices';

// Types
import {storeTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {StoreType} from "../../../types/storeTypes";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getStoreParams = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.getParams()
    .then((response) => {
      dispatch({
        type: storeTypes.GET_STORE_PARAMS,
        params: response.data
      });
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAll = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.getAll(data)
    .then((response) => {
      dispatch({
        type: storeTypes.GET_ALL_STORES,
        storesResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (store: StoreType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.create(store)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (store: StoreType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.update(store)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const remove = (storeId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  storeServices.remove(storeId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
