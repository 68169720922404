// Libraries
import React from 'react';
import {Row, Form} from 'antd';

// Components
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';


type PropsType = {
  destinations: string[];
}
const Details = (props: PropsType) => {
  const { destinations } = props;

  return (
    <Row className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-5'}>
      <Form.List name={"sealNumbers"} initialValue={[]}>
        {() => (
          <>
            {destinations.map((destination, index) => (
              <FormItemComponent key={index}
                                 name={[index]}
                                 label={`${destination}`}
                                 required={true}
                                 child={<InputComponent placeHolder={'Número de precinto'} />}
              />
            ))}
          </>
        )}
      </Form.List>
    </Row>
  );
}

export default Details;