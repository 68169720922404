// Libraries
import React from 'react';
import { Layout } from 'antd';

// Styles
import 'antd/dist/antd.css';
import './styles/app.css';
import 'moment/locale/es-mx';

// Store
import {useSelector} from 'react-redux';
import * as sessionSelectors from './store/redux/selectors/sessionSelectors';
import * as generalSelectors from './store/redux/selectors/generalSelectors';

// Components
import Router from './components/general/Router';
import Navbar from './components/general/Navbar';
import Loading from './components/general/Loading';

// Utils
import {screenSizeValidator} from "./utils/functions";

function App() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);
  const isLoading = useSelector(generalSelectors.isLoadingSelector);
  const smallWindow = screenSizeValidator('(max-width: 575px)');

  return (
    <Layout hasSider={!smallWindow} className={`flex ${smallWindow && '[&>main]:w-full [&>main]:pt-20'}`}>
      {isLoading && <Loading />}
      {isLoggedIn && <Navbar />}

      <Router />
    </Layout>
  );
}

export default App;
