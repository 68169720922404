import React, {useEffect, useState} from 'react';
import {Popconfirm, Space} from 'antd';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../store/store';
import * as storeSelectors from '../../store/redux/selectors/storeSelectors';
import {getStoreParams, remove} from '../../store/redux/actions/storeActions';

// Types
import {StoreType} from '../../types/storeTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';

// Functions and Constants
import {getTableColumns} from '../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, isEditing?: boolean, storeToEdit?: StoreType) => void;
}
const StoresTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [storeList, setStoreList] = useState<StoreType[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10,
  });
  const { rows, count, size, page } = useSelector(storeSelectors.allStoresSelector);

  useEffect(() => {
    dispatch(getStoreParams(paginationData));
  }, [paginationData]);

  useEffect(() => {
    const data: any[] = [];

    rows.map(store => {
      data.push({
        storeId: store.storeId,
        code: store.code,
        name: store.name,
        longName: store.longName ?? '-',
        isActive: store.isActive ? 'SI' : 'NO',
        address: store.address ?? '-',
        phone: store.phone ?? '-',
        zipCode: store.zipCode ?? '-',
      });
    });

    setStoreList(data);
  }, [rows]);

  const handleFormModal = (visible: boolean, storeId?: string) => {
    const storeToEdit = rows.find(store => store.storeId === storeId);

    onToggleModal(visible, true, storeToEdit);
  }

  const actionButtons = (storeId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, storeId)} />
        <Popconfirm title={'¿Deseas borrar este centro?'}
                    okText={'Confirmar'}
                    cancelText={'Cancelar'}
                    onConfirm={() => dispatch(remove(storeId))}>
          <DeleteOutlined className={'cursor-pointer hover:text-red-dkt-400'} />
        </Popconfirm>
      </Space>
    )
  };

  return (
    <TableComponent pageSize={size}
                    currentPage={page}
                    total={count}
                    dataSource={storeList}
                    scrollX={900}
                    rowKey={'storeId'}
                    columns={getTableColumns('stores', actionButtons)}
                    onChangePage={setPaginationData} />
  );
}

export default StoresTable;