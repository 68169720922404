// Libraries
import React, {FunctionComponent} from 'react';
import {Select} from 'antd';
import PropTypes from 'prop-types';


type SelectComponentType = {
  allowClear?: boolean;
  showSearch?: boolean;
  disabled?: boolean;
  searchFieldName?: string;
  placeHolder?: string;
  className?: string;
  value?: string | number | undefined;
  list: any[];
  onChange?: (e: string | number) => void;
};
const SelectComponent: FunctionComponent<SelectComponentType> = (
  {
    allowClear, showSearch, disabled, searchFieldName,
    placeHolder, className, value, list, onChange,
  }) => {
  return (
    showSearch
      ?
      <Select showSearch={true}
              optionFilterProp={"children"}
              allowClear={allowClear}
              placeholder={placeHolder}
              value={value}
              disabled={disabled}
              filterOption={(input, item) => {
                return (item![searchFieldName!] ?? '').includes(input)
              }}
              className={'appearance-none block w-full [&>div]:h-9 [&>div]:px-3 [&>div]:py-2 [&>div]:border [&>div]:shadow-none ' +
                '[&>div]:border-grey-dkt-300 [&>div]:rounded-md [&>div>span]:last:leading-4 [&>*]:sm:text-sm ' +
                '[&>div>span>input]:focus:outline-none [&>div>span>input]:focus:ring-[transparent] ' +
                className
              }
              onChange={(e) => onChange ? onChange(e) : null}>
        {list.map(item =>
          <Select.Option key={item.id} value={item.id} title={item.value}>
            {item.value}
          </Select.Option>
        )}
      </Select>
      :
      <Select allowClear={allowClear}
              placeholder={placeHolder}
              value={value}
              disabled={disabled}
              className={'appearance-none block w-full [&>div]:h-9 [&>div]:px-3 [&>div]:py-2 [&>div]:border [&>div]:shadow-none ' +
                '[&>div]:border-grey-dkt-300 [&>div]:rounded-md [&>div>span]:last:leading-4 [&>*]:sm:text-sm ' +
                className
              }
              onChange={(e) => onChange ? onChange(e) : null}>
        {list.map(item =>
          <Select.Option key={item.id} value={item.id} title={item.value}>
            {item.value}
          </Select.Option>
        )}
      </Select>
  );
}

SelectComponent.propTypes = {
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};
SelectComponent.defaultProps = {
  allowClear: false,
  disabled: false,
  placeHolder: 'Selecciona una opción',
  className: '',
  value: undefined,
  list: [],
  onChange: () => {
    return null;
  }
};

export default SelectComponent;