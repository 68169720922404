import React, {useState} from 'react';
import {Row, Tabs} from 'antd';

// Components
import StoreMaps from "./storeMaps/StoreMaps";
import UserStoreMaps from "./userStoreMaps/UserStoreMaps";


const StoreMapTabs = () => {
  const [activeKey, setActiveKey] = useState<string>("1");

  return (
    <Row>
      <Tabs key={activeKey}
            defaultActiveKey={activeKey}
            className={'overflow-x-auto'}
            onChange={setActiveKey}>
        <Tabs.TabPane tab={"Tiendas"} key={"1"}>
          <StoreMaps />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Usuarios"} key={"2"}>
          <UserStoreMaps />
        </Tabs.TabPane>
      </Tabs>
    </Row>
  );
}

export default StoreMapTabs;