// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import authServices from '../../../api/authServices';

// Components
import {ERROR_MODAL, SUCCESS_MODAL, SUCCESS_MODAL_ON_OK} from '../../../components/subComponents/responseModals';

// Utils
import {SessionType} from "../../../types/sessionTypes";

// Utils
import {redirectWindow, reloadWindow, setLocalStorageItem} from "../../../utils/functions";


export const login = (data: SessionType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  authServices.login(data)
    .then((response) => {
      for (const key in response.data) {
        if(key === 'role') {
          setLocalStorageItem(key, response.data['renderRole']);
        } else {
          setLocalStorageItem(key, response.data[key]);
        }
      }
      reloadWindow();
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}

export const register = (data: SessionType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  authServices.register(data)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}

export const confirmEmail = (code: string, redirectRoute: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  authServices.confirmEmail(code)
    .then((response) => {
      SUCCESS_MODAL_ON_OK(response.data, () => redirectWindow(redirectRoute));
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}

export const forgotPassword = (data: SessionType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  authServices.forgotPassword(data)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}

export const resetPassword = (data: SessionType, redirectRoute: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  authServices.resetPassword(data)
    .then((response) => {
      SUCCESS_MODAL_ON_OK(response.data, () => redirectWindow(redirectRoute));
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
