// Libraries
import React from 'react';
import {Row, Form, Modal, Switch} from 'antd';
import {useAppDispatch} from '../../../store/store';
import {useSelector} from "react-redux";

// Store
import { update } from '../../../store/redux/actions/userStoreMapActions';
import * as userStoreMapSelectors from "../../../store/redux/selectors/userStoreMapSelectors";

// Types
import {UserStoreMapType} from "../../../types/userStoreMapTypes";

// Components
import ButtonComponent from '../../subComponents/ButtonComponent';
import FormItemComponent from '../../subComponents/FormItemComponent';
import InputComponent from '../../subComponents/InputComponent';
import SelectComponent from "../../subComponents/SelectComponent";


type PropsType = {
  isEditing?: boolean;
  userStoreMapToEdit?: UserStoreMapType;
  onToggleModal: (visible: boolean) => void;
};
const UserStoreMapsFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isEditing, userStoreMapToEdit, onToggleModal } = props;
  const { stores } = useSelector(userStoreMapSelectors.userStoreMapParamsSelector);

  const handleSubmit = (userStoreMap: UserStoreMapType) => {
    const newUserStoreMap: UserStoreMapType = {
      storeId: userStoreMap.storeId,
    };
    dispatch(update(userStoreMapToEdit!.userStoreMapId!, newUserStoreMap));
    onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} usuario`}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            initialValues={userStoreMapToEdit ?? {}}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          <FormItemComponent name={'email'}
                             label={'Correo'}
                             required={true}
                             child={<InputComponent disabled={true}
                                                    readOnly={true} />}
          />

          <FormItemComponent name={'storeId'}
                             label={'Tienda'}
                             required={true}
                             child={<SelectComponent list={stores} />}
          />
        </Row>
      </Form>
    </Modal>
  );
}

export default UserStoreMapsFormModal;
