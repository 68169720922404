// Constants
import {TransportActionType} from '../../../types/transportTypes';
import {transportTypes} from '../types';

const initialState = {
  params: {
    stores: [], states: [], carTypes: [], carriers: [],
  },
  transportResult: { count: 0, page: 1, size: 10, rows: [] },
  detail: {}
}

const transportReducer = (state = initialState, action: TransportActionType) => {
  switch (action.type) {
    case transportTypes.GET_TRANSPORT_PARAMS:
      return {
        ...state,
        params: action.params
      };
    case transportTypes.GET_ALL_TRANSPORTS:
      return {
        ...state,
        transportResult: action.transportResult
      };
    case transportTypes.GET_TRANSPORT_DETAIL:
      return {
        ...state,
        detail: action.detail,
      };
    default:
      return state;
  }
};

export default transportReducer;
