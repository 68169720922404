// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import packageSizeServices from '../../../api/packageSizeServices';

// Types
import {packageSizeTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {PackageSizeType} from "../../../types/packageSizeTypes";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getAll = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  packageSizeServices.getAll(data)
    .then((response) => {
      dispatch({
        type: packageSizeTypes.GET_ALL_PACKAGE_SIZES,
        packageSizesResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (packageSize: PackageSizeType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  packageSizeServices.create(packageSize)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (packageSize: PackageSizeType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  packageSizeServices.update(packageSize)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const remove = (packageSizeId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  packageSizeServices.remove(packageSizeId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
