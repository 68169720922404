// Libraries
import React from 'react';
import {Row, Form, Modal, Switch} from 'antd';
import {useAppDispatch} from '../../store/store';
import {useSelector} from 'react-redux';

// Store
import * as storeSelectors from '../../store/redux/selectors/storeSelectors';
import { create, update } from '../../store/redux/actions/storeActions';

// Types
import {StoreType} from "../../types/storeTypes";

// Components
import SelectComponent from '../subComponents/SelectComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type PropsType = {
  isEditing?: boolean;
  storeToEdit?: StoreType;
  onToggleModal: (visible: boolean) => void;
};
const StoresFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { isEditing, storeToEdit, onToggleModal } = props;
  const params = useSelector(storeSelectors.storeParamsSelector);

  const handleSubmit = (store: StoreType) => {
    if(isEditing){
      store.storeId = storeToEdit!.storeId;

      dispatch(update(store));
    } else {
      store.isActive = true;

      dispatch(create(store));
    }
    onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} centro`}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            initialValues={storeToEdit ?? {}}
            onFinish={handleSubmit}>
        <Row className={'grid grid-cols-1 md:grid-cols-2 gap-x-2'}>
          {isEditing &&
            <FormItemComponent name={'isActive'}
                               className={'col-span-2'}
                               label={''}
                               required={false}
                               valuePropName={'checked'}
                               child={<Switch checkedChildren={"Activa"}
                                              unCheckedChildren={"Inactiva"} />}
            />
          }

          <FormItemComponent name={'name'}
                             label={'Nombre'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'longName'}
                             label={'Nombre largo'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'code'}
                             label={'Código'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'address'}
                             label={'Dirección'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'phone'}
                             label={'Teléfono'}
                             required={true}
                             child={<InputComponent />}
          />

          <FormItemComponent name={'locationId'}
                             label={'Código postal'}
                             required={true}
                             child={<SelectComponent list={params}
                                                     showSearch={true}
                                                     searchFieldName={'children'} />}
          />
        </Row>
      </Form>
    </Modal>
  );
}

export default StoresFormModal;