import React, {useEffect, useState} from 'react';
import {Col, Popconfirm, Row, Space} from 'antd';
import {EditOutlined, DeleteOutlined, CopyOutlined, FileAddOutlined} from '@ant-design/icons';
import moment from 'moment';

// Store
import {useAppDispatch} from '../../store/store';
import {useSelector} from 'react-redux';
import * as ctnSelectors from '../../store/redux/selectors/ctnSelectors';
import {getAllCTN, removeCTN} from '../../store/redux/actions/ctnActions';

// Types
import {CtnType} from '../../types/ctnTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';
import CtnFormModal from './CtnFormModal';
import DeleteAllByContainerFormModal from './DeleteAllByContainerFormModal';
import FiltersComponent from '../subComponents/FiltersComponent';
import ButtonComponent from '../subComponents/ButtonComponent';

// Functions and Constants
import {getFilterParameters, getTableColumns} from '../../utils/componentsUtils';
import {getLocalStorageItem, isEmpty} from '../../utils/functions';
import {ROLES, MODAL_ACTIONS} from '../../utils/constants';
import {routes} from "../../utils/routes";


const CtnTable = () => {
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10,
    filters: {}
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [deleteAllByContainerFormModalVisible, setDeleteAllByContainerFormModalVisible] = useState(false);
  const [formModalAction, setModalAction] = useState('');
  const [ctnToEdit, setCtnToEdit] = useState<CtnType | null>();
  const dispatch = useAppDispatch();
  const { rows, count, size, page } = useSelector(ctnSelectors.allCtnSelector);
  const filterParameters = getFilterParameters('ctn');
  const renderRole = getLocalStorageItem('renderRole');
  const allowedModules = ROLES[renderRole as keyof typeof ROLES].allowedModules as any[];
  const permissions = allowedModules.find(module => module.name === routes.ctn)!.permissions!;

  useEffect(() => {
    dispatch(getAllCTN(paginationData));
  }, [paginationData]);

  const handleFormModal = (visible: boolean, action?: string ,ctnId?: string) => {
    if(visible && (action !== MODAL_ACTIONS.create)){
      const ctnToEdit = JSON.parse(JSON.stringify(rows.find(ctn => ctn.ctnId === ctnId)));
      ctnToEdit!.etd = moment(ctnToEdit!.etd);
      ctnToEdit!.atd = moment(ctnToEdit!.atd);
      ctnToEdit!.eta = moment(ctnToEdit!.eta);
      setCtnToEdit(ctnToEdit);
    } else {
      setCtnToEdit(null);
    }

    setModalAction(action ?? '')
    setFormModalVisible(visible);
  }

  const actionButtons = (ctnId: string) => {
    return (
      <Space>
        <CopyOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, MODAL_ACTIONS.duplicate, ctnId)} />
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, MODAL_ACTIONS.edit, ctnId)} />
        <Popconfirm title={'¿Deseas borrar este CTN?'} okText={'Confirmar'} cancelText={'Cancelar'}
                    onConfirm={() => dispatch(removeCTN(ctnId))}>
          <DeleteOutlined className={'cursor-pointer hover:text-red-dkt-400'} />
        </Popconfirm>
      </Space>
    )
  };

  return (
    <>
      <Col md={24} xs={24} className={'mt-5'}>
        <Row>
          <Col xl={16} lg={18} md={16} xs={24}>
            <FiltersComponent filterParameters={filterParameters}
                              onChangeFilters={( value ) => setPaginationData({ page, size, filters: value })} />
          </Col>
          {permissions.canAdd && permissions.canDelete &&
            <Col xl={8} lg={6} md={8} xs={24} className={'text-right pb-4'}>
              {!isEmpty(paginationData.filters?.containerNumber) &&
                <ButtonComponent text={'Eliminar CTN'}
                              icon={<DeleteOutlined />}
                              className={'w-fit m-1 focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick={() => setDeleteAllByContainerFormModalVisible(true)}/>

              }
              <ButtonComponent text={'Nuevo CTN'}
                              icon={<FileAddOutlined />}
                              className={'w-fit m-1 focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick={() => handleFormModal(true, MODAL_ACTIONS.create)}/>
            </Col>
          }
        </Row>
      </Col>

      <TableComponent pageSize={size} currentPage={page} total={count}
                      filters={paginationData.filters ?? {}}
                      dataSource={rows} scrollX={3800} rowKey={'ctnId'}
                      columns={getTableColumns('ctn', actionButtons)}
                      onChangePage={setPaginationData} />
      {formModalVisible &&
        <CtnFormModal modalAction={formModalAction} ctnToEdit={ctnToEdit ?? null} onToggleModal={handleFormModal} />
      }
      {deleteAllByContainerFormModalVisible &&
        <DeleteAllByContainerFormModal containerNumber={paginationData.filters?.containerNumber ?? ''} 
                                     onToggleModal={setDeleteAllByContainerFormModalVisible} />
      }
    </>
  );
}

export default CtnTable;