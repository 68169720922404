// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';
import {useSelector} from 'react-redux';

// Store
import * as userSelector from '../../store/redux/selectors/userSelectors';
import { createUser, updateUser } from '../../store/redux/actions/userActions';

// Types
import {UserType} from '../../types/userTypes';

// Components
import SelectComponent from '../subComponents/SelectComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type AccessControlModalType = {
  isEditing?: boolean;
  userToEdit?: UserType;
  onToggleModal: (visible: boolean) => void;
};
const UserFormModal: FunctionComponent<AccessControlModalType> = (
  {
    isEditing, userToEdit, onToggleModal
  }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const params = useSelector(userSelector.userParamsSelector);

  const handleSubmit = (values: UserType) => {
    if(isEditing){
      values.userId = userToEdit!.userId;

      dispatch(updateUser(values));
    }else{
      dispatch(createUser(values));
    }
    onToggleModal(false);
  }

  return (
    <Modal title={`${isEditing ? 'Editar' : 'Crear'} usuario`}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={`${isEditing ? 'Editar' : 'Crear'}`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} initialValues={userToEdit ?? {}}
            onFinish = {handleSubmit}>
        <Row gutter={10}>
          <Col md={24} xs={24}>
            <FormItemComponent name={'email'} label={'Email del colaborador'} required={true}
                              newRules={[{
                                  type: 'email',
                                  message: 'El correo no es válido.',
                                },
                                {
                                    pattern:/^[a-zA-Z0-9._%+-]+@decathlon\.com$/,
                                    message:'El correo debe ser dominio Decathlon.'
                                }
                              ]}
                              child={<InputComponent disabled={isEditing} placeHolder={'Ej: correo@decathlon.com'} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'role'} label={'Rol'} required={true}
                               child={<SelectComponent list={params.roleTypes} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

UserFormModal.propTypes = {
  isEditing: PropTypes.bool,
  userToEdit: PropTypes.any,
  onToggleModal: PropTypes.func.isRequired,
};
UserFormModal.defaultProps = {
  isEditing: false,
  userToEdit: undefined,
  onToggleModal: () => {
    return null;
  }
};

export default UserFormModal;