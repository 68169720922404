import React, {useEffect, useState} from 'react';
import {Popconfirm, Space} from 'antd';
import {EditOutlined, DeleteOutlined, KeyOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../store/store';
import * as allianceSelectors from '../../store/redux/selectors/allianceSelectors';
import {generateClientSecret, getAllianceParams, remove} from '../../store/redux/actions/allianceActions';

// Types
import {AllianceType} from '../../types/allianceTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';

// Functions and Constants
import {getTableColumns} from '../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, isEditing?: boolean, allianceToEdit?: AllianceType) => void;
}
const AllianceTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [allianceList, setAllianceList] = useState<AllianceType[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10,
  });
  const params = useSelector(allianceSelectors.allianceParamsSelector);
  const { rows, count, size, page } = useSelector(allianceSelectors.allAlliancesSelector);

  useEffect(() => {
    dispatch(getAllianceParams(paginationData));
  }, [paginationData]);

  useEffect(() => {
    const data: any[] = [];

    rows.map(alliance => {
      data.push({
        allianceId: alliance.allianceId,
        name: alliance.name,
        isActive: alliance.isActive ? 'SI' : 'NO',
        type: params.find(param => param.id === alliance.type)?.value,
        color: alliance.color ?? '-'
      });
    });

    setAllianceList(data);
  }, [rows]);

  const handleFormModal = (visible: boolean, allianceId?: string) => {
    const allianceToEdit = rows.find(alliance => alliance.allianceId === allianceId);

    onToggleModal(visible, true, allianceToEdit);
  }

  const actionButtons = (allianceId: string) => {
    return (
      <Space>
        <Popconfirm title={'¿Deseas generar una nueva llave para esta alianza?'}
                    okText={'Confirmar'}
                    cancelText={'Cancelar'}
                    onConfirm={() => dispatch(generateClientSecret(allianceId))}>
          <KeyOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                       title={'Generar nueva llave'} />
        </Popconfirm>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, allianceId)} />
        <Popconfirm title={'¿Deseas borrar esta alianza?'}
                    okText={'Confirmar'}
                    cancelText={'Cancelar'}
                    onConfirm={() => dispatch(remove(allianceId))}>
          <DeleteOutlined className={'cursor-pointer hover:text-red-dkt-400'} />
        </Popconfirm>
      </Space>
    )
  };

  return (
    <>
      <TableComponent pageSize={size}
                      currentPage={page}
                      total={count}
                      dataSource={allianceList}
                      scrollX={700}
                      rowKey={'allianceId'}
                      columns={getTableColumns('alliances', actionButtons)}
                      onChangePage={setPaginationData} />
    </>
  );
}

export default AllianceTable;