import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {StoreType} from "../types/storeTypes";


function getParams() {
  return request({
    url: 'store/getParams',
    method: 'GET',
  });
}
function getAll(data: PaginationType) {
  return request({
    url: 'store/getAll',
    method: 'GET',
    params: data
  });
}
function create(store: StoreType) {
  return request({
    url: 'store/create',
    method: 'POST',
    data: store
  });
}
function update(store: StoreType) {
  return request({
    url: 'store/update',
    method: 'PUT',
    data: store
  });
}
function remove(storeId: string) {
  return request({
    url: `store/delete/${storeId}`,
    method: 'DELETE',
  });
}

export default {
  getParams, getAll,
  create,
  update,
  remove
}