// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import remissionServices from '../../../api/remissionServices';

// Types
import {remissionTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {RemissionExcelHeaderType, RemissionType} from '../../../types/remissionTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Constants
import {getBaseUrl} from '../../../api/baseURL';

// Functions
import {base64ToFile} from '../../../utils/functions';


export const getAllRemissions = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  remissionServices.getAll(data)
    .then((response) => {
      dispatch({
        type: remissionTypes.GET_ALL_REMISSIONS,
        remissionResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const uploadRemissionFile = (formData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  remissionServices.upload(formData)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllRemissions({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const downloadFile = (remissionHeader: RemissionExcelHeaderType) => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  remissionServices.downloadFile(remissionHeader)
    .then((response) => {
      base64ToFile(response.data);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const getRemissionTemplate = () => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  remissionServices.getTemplate()
    .then((response) => {
      window.open(getBaseUrl() + response.data.excelUrl);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const updateRemission = (remission: RemissionType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  remissionServices.update(remission)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllRemissions({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const removeRemission = (remissionId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  remissionServices.remove(remissionId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllRemissions({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}