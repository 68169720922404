import React, {ReactElement} from 'react';
import {Col, Divider, Form, Row, Space} from 'antd';

// Components and Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';
import TitleComponent from '../subComponents/TitleComponent';

// Assets
import logo from '../../assets/DKT_Lockup_Primary_Blue_RGB.png';

// Types
import {SessionType} from "../../types/sessionTypes";
import {routes} from "../../utils/routes";



type PropsType = {
  title: string;
  buttonText: string;
  formContent: ReactElement;
  handleSubmit: (values: SessionType) => void;
};
const Account = (props: PropsType) => {
  const [form] = Form.useForm<SessionType>();
  const { title, buttonText, formContent, handleSubmit } = props;

  return (
    <Row className={'session-container flex min-h-screen bg-grey-dkt-100 bg-no-repeat bg-cover'}
         style={{ backgroundImage: 'url(\'https://idpdecathlon.oxylane.com/assets/images/3.jpg\')' }}>
      <Col className={'sm:mx-auto sm:w-full sm:max-w-md m-auto bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'}>
        <Col md={24}>
          <img src={logo} alt={''} />
        </Col>
        <Col md={24}>
          <TitleComponent level={2}
                          text={title}
                          className={'my-5 text-center text-grey-dkt-400'} />
        </Col>

        <Form layout={"vertical"}
              form={form}
              onFinish={handleSubmit}>
          <Row className={'grid grid-cols-1'}>
            {formContent}

            <Divider className={'my-3'} />

            <ButtonComponent text={buttonText}
                             className={'w-full p-2 text-white bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none ' +
                               'focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-300 '
                             }
                             htmlType={'submit'}
            />
          </Row>
        </Form>

        <Col className={'text-center mt-5'}>
          <Space direction={'horizontal'} split={<Divider type={'vertical'} className={'bg-blue-dkt-200'} />}>
            <a href={routes.login}
               className={'text-blue-dkt-500 hover:underline font-semibold'}>
              Iniciar sesión como usuario Decathlon
            </a>
          </Space>
        </Col>
      </Col>
    </Row>
  );
}

export default Account;