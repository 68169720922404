// Constants
import {StoreActionType} from '../../../types/storeTypes';
import {storeTypes} from '../types';

const initialState = {
  storesResult: { count: 0, page: 1, size: 10, rows: [] },
  params: [],
}

const storeReducer = (state = initialState, action: StoreActionType) => {
  switch (action.type) {
    case storeTypes.GET_STORE_PARAMS:
      return {
        ...state,
        params: action.params
      };
    case storeTypes.GET_ALL_STORES:
      return {
        ...state,
        storesResult: action.storesResult
      };
    default:
      return state;
  }
};

export default storeReducer;
