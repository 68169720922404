import request from './requestWrapper';

// Types
import {
  CarrierResponseType,
  ReturnDetailType,
  TransportDetailType,
  TransportPaginationType,
  TransportType
} from "../types/transportTypes";


function getParams() {
  return request({
    url: 'transport/getParams',
    method: 'GET',
  });
}
function getDetail(transportId: string) {
  return request({
    url: `transport/getDetail/${transportId}`,
    method: 'GET',
  });
}
function getAll(data: TransportPaginationType) {
  return request({
    url: 'transport/getAllFiltered',
    method: 'POST',
    data: data
  });
}
function create(transport: TransportType) {
  return request({
    url: 'transport/create',
    method: 'POST',
    data: transport
  });
}
function uploadFile(data: FormData) {
  return request({
    url: 'transport/uploadFile',
    method: 'POST',
    data: data
  });
}
function downloadFile(fileId: string) {
  return request({
    url: `transport/downloadFile/${fileId}`,
    method: 'POST',
  });
}
function authorizedEntry(transportId: string) {
  return request({
    url: `transport/authorizedEntry/${transportId}`,
    method: 'PATCH',
  });
}
function atOriginTerminal(transportId: string) {
  return request({
    url: `transport/atOriginTerminal/${transportId}`,
    method: 'PATCH',
  });
}
function finish(transportId: string, data: { observations: string }) {
  return request({
    url: `transport/finish/${transportId}`,
    method: 'PUT',
    data: data,
  });
}
function cancel(transportId: string, data: { observations: string }) {
  return request({
    url: `transport/cancel/${transportId}`,
    method: 'PUT',
    data: data,
  });
}
function reassignCarrier(data: { transportEngineId: string; carrierId: string; reason: string; }) {
  return request({
    url: `transport/reassignCarrier`,
    method: 'PUT',
    data: data,
  });
}
function addCarrierResponse(response: CarrierResponseType) {
  return request({
    url: `transport/addCarrierResponse`,
    method: 'PUT',
    data: response,
  });
}
function addTransportDetail(detail: TransportDetailType) {
  return request({
    url: `transport/addTransportDetail`,
    method: 'PUT',
    data: detail,
  });
}
function addReturnDetail(detail: ReturnDetailType) {
  return request({
    url: `transport/addReturnDetail`,
    method: 'PUT',
    data: detail,
  });
}

export default {
  getParams, getAll, getDetail,
  create, uploadFile, downloadFile,
  authorizedEntry, atOriginTerminal, finish,
  cancel, reassignCarrier, addCarrierResponse, addTransportDetail, addReturnDetail
}