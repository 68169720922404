import {StoreType} from '../../../types/sharedTypes';


export const transportParamsSelector = (state: StoreType) => {
  return state.transportReducer.params;
}

export const allTransportsSelector = (state: StoreType) => {
  return state.transportReducer.transportResult;
}

export const transportDetailSelector = (state: StoreType) => {
  return state.transportReducer.detail;
}
