import React, {useEffect, useState} from 'react';
import {Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import {useAppDispatch} from '../../../store/store';
import * as carrierUserSelectors from '../../../store/redux/selectors/carrierUserSelectors';
import {getParams} from '../../../store/redux/actions/carrierUserActions';

// Types
import {CarrierUserType} from '../../../types/carrierUserTypes';
import {PaginationType} from "../../../types/sharedTypes";

// Components and Subcomponents
import TableComponent from '../../subComponents/TableComponent';

// Functions and Constants
import {getTableColumns} from '../../../utils/componentsUtils';


type PropsType = {
  onToggleModal: (visible: boolean, carrierUserToEdit?: CarrierUserType) => void;
}
const CarrierUsersTable = (props: PropsType) => {
  const dispatch = useAppDispatch();
  const { onToggleModal } = props;
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1, size: 10
  });
  const [list, setList] = useState<CarrierUserType[]>([]);
  const { rows, count, size, page } = useSelector(carrierUserSelectors.allCarrierUsersSelector);
  const { carriers } = useSelector(carrierUserSelectors.carrierUserParamsSelector);

  useEffect(() => {
    dispatch(getParams(paginationData));
  }, [paginationData]);

  useEffect(() => {
    const newList: CarrierUserType[] = rows.flatMap((carrierUser) => [
      {
        ...carrierUser,
        carrierName: carriers.find((carrier) => carrier.id === carrierUser.carrierId)?.value?.toString(),
        confirmed: carrierUser.confirmedEmail ? 'SI' : 'NO',
      }
    ]);

    setList(newList);
  }, [carriers, rows]);

  const handleFormModal = (visible: boolean, carrierUserId?: string) => {
    const carrierUserToEdit = rows.find(carrierUser => carrierUser.carrierUserId === carrierUserId);

    onToggleModal(visible, carrierUserToEdit);
  }

  const actionButtons = (carrierUserId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, carrierUserId)} />
      </Space>
    )
  };

  return (
    <>
      <TableComponent pageSize={size}
                      currentPage={page}
                      total={count}
                      dataSource={list}
                      scrollX={1000}
                      rowKey={'carrierUserId'}
                      columns={getTableColumns('carrierUsers', actionButtons)}
                      onChangePage={(pagination) => {
                        setPaginationData({ page: pagination.page, size: pagination.size });
                      }} />
    </>
  );
}

export default CarrierUsersTable;
