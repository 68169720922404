import React, {useEffect} from 'react';

// Components
import FormItemComponent from "../../subComponents/FormItemComponent";
import InputComponent from "../../subComponents/InputComponent";
import Account from "../Account";

// Types
import {SessionType} from "../../../types/sessionTypes";

// Utils
import {routes} from "../../../utils/routes";
import {getWindowInformation, isEmpty, redirectWindow} from "../../../utils/functions";

// Store
import {useAppDispatch} from "../../../store/store";
import {resetPassword} from "../../../store/redux/actions/authActions";


const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const { pathname, search } = getWindowInformation();
  const urlParams = new URLSearchParams(search);
  const isSupplierUser = pathname === routes.supplierAccount.resetPassword;
  const code = urlParams.get('code');

  useEffect(() => {
    if(isEmpty(code)) {
      redirectWindow(routes.login);
    }
  }, [code]);

  const handleSubmit = (values: SessionType) => {
    const newValues = {
      code: code ?? '',
      password: values.password,
    };
    dispatch(resetPassword(newValues, isSupplierUser ? routes.supplierAccount.login : routes.carrierAccount.login));
  };

  return (
    <Account title={'Nueva contraseña'}
             buttonText={'Restablecer'}
             handleSubmit={handleSubmit}
             formContent={
               <>
                 <FormItemComponent name={'password'}
                                    label={'Contraseña'}
                                    required={true}
                                    child={<InputComponent type={'password'} />}
                 />

                 <FormItemComponent name={'confirmPassword'}
                                    label={'Confirmación de contraseña'}
                                    required={true}
                                    dependencies={['password']}
                                    newRules={[
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(new Error('Las contraseñas no coinciden'));
                                        },
                                      })
                                    ]}
                                    child={<InputComponent type={'password'} />}
                 />
               </>
             } />
  );
}

export default ResetPassword;