import {CopyOutlined} from "@ant-design/icons";
import {Col, Input} from "antd";

// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import allianceServices from '../../../api/allianceServices';

// Types
import {allianceTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {AllianceType} from "../../../types/allianceTypes";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Utils
import {copyToClipboard} from "../../../utils/functions";


export const getAllianceParams = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.getParams()
    .then((response) => {
      dispatch({
        type: allianceTypes.GET_ALLIANCE_PARAMS,
        params: response.data
      });
      dispatch(getAll(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAll = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.getAll(data)
    .then((response) => {
      dispatch({
        type: allianceTypes.GET_ALL_ALLIANCES,
        alliancesResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (alliance: AllianceType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.create(alliance)
    .then((response) => {
      SUCCESS_MODAL(
        <Col>
          <p>{response.data.status}. Puede copiar la siguiente llave creada para la alianza:</p>
          <Input className={'w-full mt-4'}
                 id={'alliance-key-input'}
                 readOnly={true}
                 suffix={<CopyOutlined title={'Copiar llave'}
                                       onClick={() => copyToClipboard('alliance-key-input')} />}
                 value={response.data.clientSecret} />
        </Col>
      );
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const generateClientSecret = (allianceId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.generateClientSecret(allianceId)
    .then((response) => {
      SUCCESS_MODAL(
        <Col>
          <p>{response.data.status}. Puede copiar la siguiente llave creada para la alianza:</p>
          <Input className={'w-full mt-4'}
                 id={'alliance-key-input'}
                 readOnly={true}
                 suffix={<CopyOutlined title={'Copiar llave'}
                                       onClick={() => copyToClipboard('alliance-key-input')} />}
                 value={response.data.clientSecret} />
        </Col>
      );
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const update = (alliance: AllianceType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.update(alliance)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const remove = (allianceId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  allianceServices.remove(allianceId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAll({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
